import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";

import {
  Flex,
  Box,
  SimpleGrid,
  useToast,
  useBreakpointValue,
  Button,
  useTheme,
} from "@chakra-ui/react";

import MainPanelError from "components/ui/MainPanelError";
import { errorHandler, hexToRgba } from "utils/helpers";
import Loading from "components/ui/Loading";

import { SavedFolderProps } from "models/bookmarks/SavedProps";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { useMemo, useState } from "react";
import { environment } from "environments";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import NoFolders from "components/library/bookmarks/NoFolders";
import FoldersItem from "components/library/bookmarks/FoldersItem";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useUsersAPI } from "api/useUsersAPI";

export default function FoldersList() {
  const { user } = useSelector(selectCurrentAuthData);

  // APIs
  const { saveUserActivity } = useUsersAPI();

  // Hooks
  const { teamId } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { loading, error, savedFolders } = useSelector(selectCurrentSavedData);

  const inTeamView = window.location.pathname.includes("team");

  // Theme
  const { colors } = useTheme();

  // States
  const [deleting, setDeleting] = useState(false);
  const [updatingFolder, setUpdatingFolder] = useState(false);
  const [foldersFilter, setFoldersFilter] = useState<
    "all" | "personal" | "team"
  >("all");
  const [renameModal, setRenameModal] = useState<{
    isOpen: boolean;
    folder?: SavedFolderProps;
  }>({ isOpen: false });

  const { hasFoldersToShow, foldersToShow } = useMemo(() => {
    let foldersToShow: SavedFolderProps[] = [];
    if (inTeamView) {
      foldersToShow = savedFolders?.filter(
        (x) => x?.isShared === true && x?.team_id === teamId
      );
    } else {
      if (foldersFilter === "team") {
        foldersToShow = savedFolders?.filter((x) => x?.isShared === true);
      } else if (foldersFilter === "personal") {
        foldersToShow = savedFolders?.filter((x) => x?.isShared === false);
      } else {
        foldersToShow = savedFolders;
      }
    }

    return {
      hasFoldersToShow: foldersToShow?.length > 0,
      foldersToShow: foldersToShow,
    };
  }, [foldersFilter, inTeamView, savedFolders, teamId]);

  function handleOpenRenameModal(folder: SavedFolderProps) {
    setRenameModal({ isOpen: true, folder });
  }

  function handleCloseRenameModal() {
    setRenameModal({ isOpen: false });
  }

  // Handlers
  const setFilter = (filter: "all" | "personal" | "team") => {
    setFoldersFilter(filter);
  };

  async function handleDeleteFolder(folderId: string) {
    if (!folderId) return;

    setDeleting(false);

    try {
      setDeleting(true);

      const folderToDelete = foldersToShow?.filter(
        (folder: SavedFolderProps) => folder.id === folderId
      )[0];

      await axiosPrivate.delete(
        `${environment.BACKEND_API}/api/remove_elements?folderId=${folderId}`
      );

      // Save User activity log
      const activity = {
        user_id: user.id,
        activity: `Deleted bookmark folder: ${folderToDelete.name}`,
      };
      await saveUserActivity(activity);

      // update store
      let newFolders: SavedFolderProps[] = foldersToShow?.filter(
        (folder: SavedFolderProps) => folder.id !== folderId
      );
      dispatch(setSuccess(newFolders));

      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Folder deleted successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        title: "Error Deleting Folder",
        status: "error",
        duration: 1500,
      });
    } finally {
      setDeleting(false);
    }
  }

  async function handleUpdateFolderTitle(title: string, id: string) {
    if (!id) return;

    setUpdatingFolder(true);

    try {
      setUpdatingFolder(true);

      await axiosPrivate.put(
        `${environment.BACKEND_API}/api/remove_elements?folderId=${id}`,
        { newName: title, folderId: id }
      );

      setUpdatingFolder(false);
      let updatedFolders: SavedFolderProps[] = foldersToShow?.map(
        (folder: SavedFolderProps) =>
          folder.id === id ? { ...folder, name: title } : folder
      );
      setRenameModal({ isOpen: false });
      dispatch(setSuccess(updatedFolders));
      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Folder updated successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        title: "Error updating Folder",
        status: "error",
        duration: 1500,
      });
    } finally {
      setUpdatingFolder(false);
    }
  }

  function handleOpenFolder(id: string) {
    const currentSection = window.location.pathname.includes("team")
      ? `/team/${teamId}/folders`
      : "/data/bookmarks";
    navigate(`${currentSection}/${id}`);
  }

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  const listHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)", // 150 = (8x2) + 62 + 24 + 32 + 16 : py + nav + gap + breadcrumb
    xl: "calc(100vh - 162px)", // 158 = (12x2) + 62 + 24 + 32 + 16
    "2xl": "calc(100vh - 178px)", // 174 = (24x2) + 62 + 24 + 32 + 16
  });

  // Style
  const listStyle = {
    height: listHeight,
    width: "100%",
    padding: "6px 12px 6px 0",
  };

  if (!!error) {
    return (
      <Flex h={listHeight} w={"100%"}>
        <MainPanelError errorMessage={errorHandler(error).message} />
      </Flex>
    );
  }

  if (!!loading) {
    return (
      <Flex h={listHeight} w={"100%"}>
        <Loading message="Loading folders.." />
      </Flex>
    );
  }

  return (
    <>
      {!inTeamView && (
        <Flex w={"100%"} align={"center"} ml={"auto"} mr={1} gap={2} mb={6}>
          {/* All folders button */}
          <Button
            onClick={() => setFilter("all")}
            fontSize={"14px"}
            w={"fit-content"}
            h={"fit-content"}
            fontWeight={"500"}
            color={"blue.500"}
            borderColor={"blue.300"}
            borderWidth={1}
            borderStyle={"dashed"}
            bg={
              foldersFilter === "all"
                ? hexToRgba(colors.blue[300], 0.15)
                : "transparent"
            }
            borderRadius={"30px"}
            transition={"all ease .2s"}
            py={1.5}
            px={4}
            gap={0}
            textAlign={"center"}
            _hover={{ bg: hexToRgba(colors.blue[300], 0.15) }}
            _focus={{ bg: hexToRgba(colors.blue[300], 0.15) }}
          >
            All Folders
          </Button>

          {/* Personal folders button */}
          <Button
            onClick={() => setFilter("personal")}
            fontSize={"14px"}
            w={"fit-content"}
            h={"fit-content"}
            fontWeight={"500"}
            color={"blue.500"}
            borderColor={"blue.300"}
            borderWidth={1}
            borderStyle={"dashed"}
            bg={
              foldersFilter === "personal"
                ? hexToRgba(colors.blue[300], 0.15)
                : "transparent"
            }
            borderRadius={"30px"}
            transition={"all ease .2s"}
            py={1.5}
            px={4}
            gap={0}
            textAlign={"center"}
            _hover={{ bg: hexToRgba(colors.blue[300], 0.15) }}
            _focus={{ bg: hexToRgba(colors.blue[300], 0.15) }}
          >
            Personal Folders
          </Button>

          {/* shared with me button */}
          <Button
            onClick={() => setFilter("team")}
            fontSize={"14px"}
            w={"fit-content"}
            h={"fit-content"}
            fontWeight={"500"}
            color={"blue.500"}
            borderColor={"blue.300"}
            borderWidth={1}
            borderStyle={"dashed"}
            bg={
              foldersFilter === "team"
                ? hexToRgba(colors.blue[300], 0.15)
                : "transparent"
            }
            borderRadius={"30px"}
            transition={"all ease .2s"}
            py={1.5}
            px={4}
            gap={0}
            textAlign={"center"}
            _hover={{ bg: hexToRgba(colors.blue[300], 0.15) }}
            _focus={{ bg: hexToRgba(colors.blue[300], 0.15) }}
          >
            Team Folders
          </Button>
        </Flex>
      )}

      {hasFoldersToShow ? (
        <CustomScrollBar style={listStyle}>
          <SimpleGrid columns={3} spacing={4}>
            {foldersToShow?.map((folder: SavedFolderProps) => {
              return (
                <FoldersItem
                  key={"folder-" + folder.id}
                  folder={folder}
                  deleting={deleting}
                  onDeleteFolder={handleDeleteFolder}
                  onUpdateFolderTitle={handleUpdateFolderTitle}
                  updatingFolder={updatingFolder}
                  onClick={() => handleOpenFolder(folder.id)}
                  renameModal={renameModal}
                  onOpenRenameModal={handleOpenRenameModal}
                  onCloseRenameModal={handleCloseRenameModal}
                />
              );
            })}
          </SimpleGrid>
        </CustomScrollBar>
      ) : (
        <NoFolders />
      )}
    </>
  );
}
