import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text, Icon, useToast } from '@chakra-ui/react';
import { IoDocuments, IoCloudUpload } from 'react-icons/io5';
import { useState, useRef, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentAuthData } from 'redux/features/auth/authSlice';
import { selectCurrentPostsData } from 'redux/features/posts/postsSlice';
import { environment } from 'environments';
import { axiosPrivate } from 'api/axios';
import { errorHandler } from 'utils/helpers';
import UploadModalContent from './UploadModalContent';

interface MaterialUploadModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    isTeamContext?: boolean;
    teamId?: string;
}

export interface FileWithPath extends File {
    preview?: string;
    path?: string;
}

const UploadModal: React.FC<MaterialUploadModalProps> = ({ isOpen, setIsOpen, isTeamContext = false, teamId }) => {
    const toast = useToast();
    const { user } = useSelector(selectCurrentAuthData);
    const { teamData } = useSelector(selectCurrentPostsData);

    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState<FileWithPath[]>([]);
    const [uploadType, setUploadType] = useState<'files' | 'folder'>('files');
    const [privateToUploader, setPrivateToUploader] = useState(false);
    const [teamsSharedWith, setTeamsSharedWith] = useState<string[]>([]);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const folderInputRef = useRef<HTMLInputElement>(null);

    const selectedTeam = isTeamContext ? teamData?.find(team => team.id === teamId) : undefined;
    const isOwner = isTeamContext && user?.id === selectedTeam?.owner;

    const handleClose = () => {
        setIsOpen(false);
        setSelectedFiles([]);
        setUploadProgress(0);
        setPrivateToUploader(false);
        setTeamsSharedWith([]);
        if (fileInputRef.current) fileInputRef.current.value = '';
        if (folderInputRef.current) folderInputRef.current.value = '';
    };

    const handleUploadMaterials = async () => {
        if (!selectedFiles.length) {
            toast({
                title: 'No materials selected',
                description: `Please select ${uploadType === 'files' ? 'files' : 'a folder'} to upload`,
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setUploading(true);
        const formData = new FormData();

        if (isTeamContext) {
            formData.append('team_id', teamId ?? '');
            formData.append('private_to_uploader', privateToUploader.toString());
        } else {
            formData.append('private_to_uploader', 'true');
            teamsSharedWith.forEach(tm_id => formData.append('teams_shared_with[]', tm_id));
        }

        selectedFiles.forEach(file => {
            formData.append('files', file);
            formData.append('paths', file.path || '');
        });

        try {
            await axiosPrivate.post(`${environment.MOLECULELAKE_DATA_API}/pipeline/materials`, formData, {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    if (total) setUploadProgress(Math.round((loaded / total) * 100));
                },
                headers: { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' },
            });

            toast({
                title: 'Upload successful',
                description: 'Your materials have been uploaded successfully and added to the processing queue',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            handleClose();
        } catch (error: any) {
            toast({
                title: 'Upload failed',
                description: errorHandler(error).message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setUploading(false);
            setUploadProgress(0);
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesWithPaths = Array.from(event.target.files).map(file => {
                const relativePath = (file as any).path || file.webkitRelativePath;
                return Object.assign(file, { preview: URL.createObjectURL(file), path: relativePath });
            });
            setSelectedFiles(prev => [...prev, ...filesWithPaths]);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="lg">
            <ModalOverlay backdropFilter="blur(3px)" />
            <ModalContent alignSelf="center" p={4} borderRadius="12px" bg="white" boxShadow="lg">
                <ModalHeader display="flex" alignItems="center" gap={3} px={2}>
                    <Icon as={IoDocuments} bg="primary.500" color="white" boxSize="32px" borderRadius="8px" p="6px" />
                    <Text fontSize="xl" fontWeight="600" color="gray.700">Upload Materials</Text>
                </ModalHeader>
                <ModalBody>
                    <UploadModalContent
                        handleFileChange={handleFileChange}
                        setUploadType={setUploadType}
                        isTeamContext={isTeamContext}
                        isOwner={isOwner}
                        fileInputRef={fileInputRef}
                        folderInputRef={folderInputRef}
                        privateToUploader={privateToUploader}
                        setPrivateToUploader={setPrivateToUploader}
                        teamsSharedWith={teamsSharedWith}
                        setTeamsSharedWith={setTeamsSharedWith}
                        selectedFiles={selectedFiles}
                        uploading={uploading}
                        uploadProgress={uploadProgress}
                    />
                </ModalBody>
                <ModalFooter gap={3}>
                    <Button variant="ghost" onClick={handleClose} isDisabled={uploading} colorScheme="gray" size="lg" borderRadius="lg">
                        Cancel
                    </Button>
                    <Button
                        colorScheme="primary"
                        onClick={handleUploadMaterials}
                        isLoading={uploading}
                        loadingText="Uploading..."
                        leftIcon={<IoCloudUpload />}
                        size="lg"
                        borderRadius="lg"
                    >
                        Upload
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UploadModal;