import { Text, VStack, Progress, Box, Tabs, TabList, Tab, TabPanels, TabPanel, InputGroup, Checkbox, Flex, Icon, useTheme, Card, Avatar, Stack, CardBody, Heading } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentPostsData } from 'redux/features/posts/postsSlice';
import Select, { StylesConfig } from 'react-select';
import { TbCheck, TbShare } from 'react-icons/tb';
import { hexToRgba } from 'utils/helpers';
import { TeamProps } from 'models/posts/PostProps';
import { FileWithPath } from './UploadModel';

interface UploadModalContentProps {
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    setUploadType: (str: 'files' | 'folder') => void;
    isTeamContext?: boolean;
    isOwner?: boolean;
    fileInputRef: React.RefObject<HTMLInputElement>;
    folderInputRef: React.RefObject<HTMLInputElement>;
    privateToUploader: boolean;
    setPrivateToUploader: (state: boolean) => void;
    teamsSharedWith: string[];
    setTeamsSharedWith: (state: string[]) => void;
    selectedFiles: FileWithPath[];
    uploading: boolean;
    uploadProgress: number;
}

interface Option {
    label: string;
    value: string;
    id: string;
    isSelected: boolean;
}

const UploadModalContent: React.FC<UploadModalContentProps> = ({
    handleFileChange,
    setUploadType,
    isTeamContext = false,
    isOwner = false,
    fileInputRef,
    folderInputRef,
    privateToUploader,
    setPrivateToUploader,
    teamsSharedWith,
    setTeamsSharedWith,
    selectedFiles,
    uploading,
    uploadProgress,
}) => {
    const { teamData } = useSelector(selectCurrentPostsData);
    const { colors } = useTheme();
    const [sharedWithTeam, setSharedWithTeam] = useState(false);

    const teams: Option[] = (teamData || []).map((team: TeamProps) => ({
        label: team.name,
        value: team.name,
        id: team.id,
        isSelected: teamsSharedWith.includes(team.id),
    }));

    const selectInputStyle: StylesConfig<Option, true> = useMemo(() => ({
        control: (base: any) => ({
            ...base,
            fontSize: '12px',
            marginTop: '10px',
            borderColor: hexToRgba(colors.primary[600], 0.1),
            ':focus': { borderColor: hexToRgba(colors.primary[600], 0.1) },
            '&:hover': { cursor: 'pointer' },
        }),
        multiValueLabel: (base) => ({
            ...base,
            backgroundColor: hexToRgba(colors.blue[600], 0.1),
            color: 'highlight.primary',
        }),
        multiValueRemove: (base) => ({
            ...base,
            backgroundColor: hexToRgba(colors.primary[600], 0.1),
            color: 'highlight.primary',
        }),
        placeholder: (base) => ({ ...base, fontSize: '12px', color: colors.gray[400] }),
        option: (base, { isSelected }: { isSelected: boolean }) => ({
            ...base,
            paddingLeft: 10,
            fontSize: '12px',
            fontWeight: isSelected ? '500' : '400',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor: isSelected ? hexToRgba(colors.highlight.primary, 0.25) : 'transparent',
            color: isSelected ? colors.primary[100] : colors.primary[800],
            '&:hover': {
                backgroundColor: isSelected ? hexToRgba(colors.highlight.primary, 0.14) : hexToRgba(colors.black, 0.04),
                color: isSelected ? colors.primary[100] : colors.primary[800],
                cursor: 'pointer',
            },
        }),
    }), [colors]);

    const handleTeamSelection = (selectedOptions: readonly Option[]) => {
        const selectedTeamIds = selectedOptions.map(option => option.id);
        setTeamsSharedWith(selectedTeamIds);
    };

    useEffect(() => {
        if (!isTeamContext) setTeamsSharedWith([]);
    }, [isTeamContext]);

    const acceptedFormats = '.pdf,.jpg,.jpeg,.png,.doc,.docx,.odt,.xls,.xlsx,.ods,.ppt,.pptx,.odp,.txt,.csv,.json,.html';

    return (
        <VStack spacing={6} align="start" w="100%">
            <Tabs w="100%" variant="soft-rounded" colorScheme="primary" onChange={(index) => setUploadType(index === 0 ? 'files' : 'folder')}>
                <TabList mb={4}>
                    <Tab>Upload Files</Tab>
                    <Tab>Upload Folder</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p={0}>
                        <VStack spacing={4} align="stretch">
                            <Box borderWidth="2px" borderStyle="dashed" borderColor="gray.200" borderRadius="lg" p={6} textAlign="center" _hover={{ borderColor: 'primary.500' }} transition="all 0.2s">
                                <InputGroup>
                                    <Box
                                        as="input"
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        multiple
                                        accept={acceptedFormats}
                                        sx={{
                                            width: '100%',
                                            '::file-selector-button': {
                                                height: '40px',
                                                padding: '0 20px',
                                                background: 'var(--chakra-colors-primary-500)',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '8px',
                                                cursor: 'pointer',
                                                marginRight: '12px',
                                                fontWeight: '500',
                                                transition: 'all 0.2s',
                                                _hover: { background: 'var(--chakra-colors-primary-600)' },
                                            },
                                        }}
                                    />
                                </InputGroup>
                            </Box>
                        </VStack>
                    </TabPanel>
                    <TabPanel p={0}>
                        <VStack spacing={4} align="stretch">
                            <Box borderWidth="2px" borderStyle="dashed" borderColor="gray.200" borderRadius="lg" p={6} textAlign="center" _hover={{ borderColor: 'primary.500' }} transition="all 0.2s">
                                <InputGroup>
                                    <Box
                                        as="input"
                                        type="file"
                                        ref={folderInputRef}
                                        onChange={handleFileChange}
                                        // @ts-ignore
                                        webkitdirectory=""
                                        // @ts-ignore
                                        directory=""
                                        mozdirectory=""
                                        accept={acceptedFormats}
                                        sx={{
                                            width: '100%',
                                            '::file-selector-button': {
                                                height: '40px',
                                                padding: '0 20px',
                                                background: 'var(--chakra-colors-primary-500)',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '8px',
                                                cursor: 'pointer',
                                                marginRight: '12px',
                                                fontWeight: '500',
                                                transition: 'all 0.2s',
                                                _hover: { background: 'var(--chakra-colors-primary-600)' },
                                            },
                                        }}
                                    />
                                </InputGroup>
                            </Box>
                        </VStack>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Box w="100%">
                <Text fontSize="sm" fontWeight="600" color="gray.700" mb={1}>Accepted File Types:</Text>
                <Text fontStyle="italic" fontSize="xs" color="gray.400">
                    {acceptedFormats.split(',').map(ext => ext.trim().substring(1)).join(', ')}
                </Text>
            </Box>

            {isTeamContext ? (
                isOwner && (
                    <Checkbox
                        isChecked={privateToUploader}
                        onChange={(e) => setPrivateToUploader(e.target.checked)}
                        colorScheme="primary"
                    >
                        I am the only one who can view this.
                    </Checkbox>
                )
            ) : (
                !!teamData?.length && (
                    <>
                        <Flex align="center">
                            <Icon as={TbShare} color="primary.500" boxSize="20px" mr="10px" />
                            <Text fontSize="sm" fontWeight="600" color="gray.700">Share with:</Text>
                        </Flex>
                        <Flex ml="5%" gap="20px">
                            <Checkbox
                                isChecked={!sharedWithTeam}
                                onChange={(e) => {
                                    setSharedWithTeam(false);
                                    setTeamsSharedWith([]);
                                }}
                            >
                                <Text fontSize="sm" color="gray.600">Just me</Text>
                            </Checkbox>
                            <Checkbox
                                isChecked={sharedWithTeam}
                                onChange={(e) => {
                                    setSharedWithTeam(true);
                                    if (teamData?.length === 1) setTeamsSharedWith([teamData[0].id]);
                                }}
                            >
                                <Text fontSize="sm" color="gray.600">My team(s)</Text>
                            </Checkbox>
                        </Flex>
                        {sharedWithTeam && teamData?.length > 0 && (
                            <Flex width="95%" flexDir="column" ml="5%">
                                <Box w="100%" mb={2}>
                                    <Select
                                        styles={selectInputStyle}
                                        placeholder="Select teams to share with"
                                        options={teams}
                                        isSearchable={true}
                                        isMulti={true}
                                        defaultValue={teamsSharedWith.length > 0 ? teams.filter(t => teamsSharedWith.includes(t.id)) : (teamData?.length === 1 ? [teams[0]] : [])}
                                        isDisabled={teamData?.length === 1}
                                        onChange={handleTeamSelection}
                                        noOptionsMessage={() => <Text fontSize="sm">No more teams to select</Text>}
                                    />
                                </Box>
                                {teamsSharedWith.map((teamId) => {
                                    const team = teamData.find((t: TeamProps) => t.id === teamId);
                                    if (!team) return null;
                                    return (
                                        <Card
                                            key={team.name}
                                            p="10px"
                                            mt="5px"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            borderRadius="6px"
                                            borderWidth={1}
                                            borderColor={hexToRgba(colors.highlight.primary, 0.16)}
                                            bg={hexToRgba(colors.highlight.primary, 0.12)}
                                            direction={{ base: 'column', sm: 'row' }}
                                            overflow="hidden"
                                            variant="outline"
                                            _hover={{ textDecoration: 'none', bg: hexToRgba(colors.highlight.primary, 0.12) }}
                                        >
                                            <Avatar fontSize="16px" w="40px" h="40px" name={team.name} src="" />
                                            <Stack w="100%">
                                                <CardBody pl="20px" p={0}>
                                                    <Heading size="xs" fontWeight="500">{team.name}</Heading>
                                                    <Text size="xs" color="gray.500">members: {team.members.length}</Text>
                                                </CardBody>
                                            </Stack>
                                            <Icon as={TbCheck} bg="transparent" color="highlight.primary" boxSize="22px" borderRadius="6px" p="3px" />
                                        </Card>
                                    );
                                })}
                            </Flex>
                        )}
                    </>
                )
            )}

            {selectedFiles.length > 0 && (
                <VStack w="100%" align="flex-start" spacing={3}>
                    <Text fontSize="sm" fontWeight="600" color="gray.700">Selected files ({selectedFiles.length})</Text>
                    <Box maxH="200px" w="100%" overflowY="auto" borderRadius="md" borderWidth="1px" borderColor="gray.200" p={3}>
                        {selectedFiles.map((file, index) => (
                            <Text key={index} fontSize="sm" color="gray.600" py={1}>{file.name}</Text>
                        ))}
                    </Box>
                </VStack>
            )}

            {uploading && (
                <Box w="100%">
                    <Text fontSize="sm" color="gray.600" mb={2}>Uploading... {uploadProgress}%</Text>
                    <Progress value={uploadProgress} size="sm" colorScheme="primary" borderRadius="full" />
                </Box>
            )}
        </VStack>
    );
};

export default UploadModalContent;