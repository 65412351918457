import {
  Box,
  Text,
  SimpleGrid,
  Card,
  Heading,
  CardBody,
  Icon,
  Circle,
  Flex,
  useTheme,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import {
  coreSources,
  MRNASources,
  proprietarySources,
  SourceProps,
} from "../helpers";
import { hexToRgba } from "utils/helpers";
import ToolsSection from "components/library/core/layers/tools/ToolsSection";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

interface SourcesSectionProps {
  sources: SourceProps[];
  layerName: string;
  onOpen: (p: string) => void;
}

export default function SourcesList() {
  const { user } = useSelector(selectCurrentAuthData);

  // Hooks
  const navigate = useNavigate();
  const { layerName } = useParams();

  // Helpers
  function openSource(path: string) {
    navigate(`/data/core/${layerName}/data/${path.toLowerCase()}`);
  }

  const isMoleculeLake = layerName === "MoleculeLake";
  const isMRNALake = layerName === "mRNALake";
  const isPrivate = layerName === "Private";
  let sources: SourceProps[] = [];

  if (isMoleculeLake) sources = coreSources;
  else if (isMRNALake) {
    if (user.is_internal) {
      sources = MRNASources;
    } else {
      const _sources = MRNASources.filter(
        (source: SourceProps) =>
          source?.key === "PROTEINS" || source?.key === "LITERATURE"
      );
      sources = _sources;
    }
  } else if (isPrivate) sources = proprietarySources;

  return (
    <Flex direction={"column"} gap={"60px"}>
      {/* sources */}
      <SourcesSection
        sources={sources}
        layerName={layerName ?? ""}
        onOpen={openSource}
      />

      {/* tools */}
      {(isMRNALake || isMoleculeLake) && user.is_internal && <ToolsSection />}
    </Flex>
  );
}

function SourcesSection({ sources, layerName, onOpen }: SourcesSectionProps) {
  // Theme
  const { colors } = useTheme();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgCard = isLight ? "background" : `lightBackground`;

  return (
    <Flex direction={"column"} gap={2}>
      <Text
        fontSize={"20px"}
        fontWeight={"500"}
        lineHeight={1}
        bgGradient={`linear(to-l, ${colors.highlight.primary} 0%, ${colors.secondary[600]} 100%)`}
        bgClip={"text"}
        color={"transparent"}
        w={"fit-content"}
        pl={2}
      >
        Data
      </Text>

      <SimpleGrid columns={2} spacing={6} p={1}>
        {sources?.map((source, index) => (
          <Box key={"core-source-" + index}>
            <Card
              bg={bgCard}
              h={"120px"}
              _hover={{
                bg: hexToRgba(colors.secondary[100], 0.15),
                cursor: "pointer",
              }}
              onClick={() => onOpen(source.path)}
            >
              <CardBody>
                <Flex gap={6} align={"center"}>
                  <Circle
                    size="50px"
                    bg={source.color}
                    color="white"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon as={source.icon} />
                  </Circle>

                  <Box>
                    <Heading size="sm" textTransform={"capitalize"}>
                      {layerName === "Private" ? "MATERIALS" : source.key}
                    </Heading>
                    <Text
                      fontSize={"sm"}
                      maxH="3em"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {source.description}
                    </Text>
                    {source.source && (
                      <Text fontSize={"xs"} color={"neutral.800"} mt={2}>
                        By {source.source}
                      </Text>
                    )}
                  </Box>
                </Flex>
              </CardBody>
            </Card>
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  );
}
