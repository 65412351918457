import React from 'react';
import { FaFilePdf, FaFileWord, FaFileImage, FaFileAlt, FaFileExcel, FaFilePowerpoint, FaFileCsv, FaFileCode } from 'react-icons/fa';
import { IconType } from 'react-icons';

interface FileTypeIconProps {
    type: string;
}

const iconMap: Record<string, IconType> = {
    'pdf': FaFilePdf,
    'doc': FaFileWord,
    'docx': FaFileWord,
    'odt': FaFileWord, // OpenDocument Text
    'xls': FaFileExcel,
    'xlsx': FaFileExcel,
    'ods': FaFileExcel, // OpenDocument Spreadsheet
    'ppt': FaFilePowerpoint,
    'pptx': FaFilePowerpoint,
    'odp': FaFilePowerpoint, // OpenDocument Presentation
    'txt': FaFileAlt,
    'csv': FaFileCsv,
    'json': FaFileCode,
    'html': FaFileCode,
    'png': FaFileImage,
    'jpg': FaFileImage,
    'jpeg': FaFileImage,
    'cdf': FaFileAlt, // Fallback for CDFV2 (generic legacy Office)
    'default': FaFileAlt
};


export const FileTypeIcon: React.FC<FileTypeIconProps> = ({ type }) => {
    // Map content types from Python to file extensions
    const contentTypeToExtension: Record<string, string> = {
        'application/pdf': 'pdf',
        'application/msword': 'doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/vnd.oasis.opendocument.text': 'odt',
        'application/vnd.ms-excel': 'xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/vnd.oasis.opendocument.spreadsheet': 'ods',
        'application/vnd.ms-powerpoint': 'ppt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
        'application/vnd.oasis.opendocument.presentation': 'odp',
        'application/CDFV2': 'cdf', // Legacy Office files (e.g., .doc, .xls, .ppt)
        'text/plain': 'txt',
        'text/csv': 'csv',
        'application/json': 'json',
        'text/html': 'html',
        'image/png': 'png',
        'image/jpeg': 'jpeg' // Covers both .jpg and .jpeg
    };

    const ext = contentTypeToExtension[type] || 'default';
    const IconComponent = iconMap[ext];

    return <IconComponent />;
};

export const formatFileSize = (bytes: number) => {
    if (bytes < 1024) return `${bytes} B`;
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
    return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
};
