import {
  Box,
  Button,
  Heading,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdAssessment } from "react-icons/md";
import { useOutletContext } from "react-router-dom";

interface Question {
  text: string;
  type: string;
  choices?: {
    a: string;
    b: string;
    c: string;
    d: string;
  };
  studentAnswer?: string;
}

interface Props {
  assessment: {
    option: string;
    data: {
      assessment_header: string;
      assessment_instructions: string;
      questions: Question[];
    };
  };
}

export default function RenderAssessment({ assessment }: Props) {
  const { colors } = useTheme();
  const toast = useToast();
  const { handleSendQuestion }: { handleSendQuestion: (q: string) => void } =
    useOutletContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<Question[]>(
    assessment.data.questions
  );

  const updateFormData = (value: any, index: number) => {
    let formDataTemp = formData;
    formDataTemp[index].studentAnswer = value;
    setFormData(formDataTemp);
  };

  const allFieldsFilled = (): boolean => {
    return formData.every(
      (question) =>
        question.studentAnswer !== undefined &&
        question.studentAnswer.trim() !== ""
    );
  };

  const submitAssessment = () => {
    if (allFieldsFilled() === true) {
      let payloadAnswer = "Please **MARK** the submitted assessment below:-\n";
      formData.forEach((question, index) => {
        payloadAnswer += `${index + 1}. ${question.text}\n\n`;
        if (question.studentAnswer) {
          if (
            assessment.data.questions[index].type === "Multiple-Choice" ||
            assessment.data.questions[index].type === "True/False"
          ) {
            payloadAnswer += `__Choices__:- ${JSON.stringify(
              assessment.data.questions[index].choices
            )}`;
          }
          payloadAnswer += `**Answer Given**:- ${question.studentAnswer}\n\n`;
        } else {
          payloadAnswer += `**Answer Given**:- None\n\n`;
        }
      });
      handleSendQuestion(payloadAnswer);
      setIsOpen(false);
    } else {
      toast({
        description: "Please fill in all the questions before submitting them!",
        status: "warning",
        position: "top-right",
      });
    }
  };

  const renderQuestion = (question: Question, index: number) => {
    return (
      <ListItem key={index}>
        <Text textAlign={"left"}>{question.text}</Text>

        {(question.type === "Short-Answer" ||
          question.type === "Essay" ||
          question.type === "Coding" ||
          question.type === "Problem-Solving" ||
          question.type === "Case-Study") && (
          <Textarea
            w={"100%"}
            color={"gray.600"}
            fontSize={"14px"}
            variant={"flushed"}
            minHeight={
              question.type === "Short-Answer"
                ? 15
                : question.type === "Essay"
                ? 40
                : 60
            }
            value={formData[index].studentAnswer}
            onChange={(e) => updateFormData(e.target.value, index)}
            placeholder={
              question.type === "Short-Answer"
                ? "Enter your answer"
                : question.type === "Coding"
                ? "Your code here"
                : "Write you answer here"
            }
            _placeholder={{ fontSize: "14px", color: "gray.600" }}
            bg={"background"}
            borderColor={"gray.200"}
            _hover={{ borderColor: "gray.300" }}
            _focusVisible={{ borderColor: "blue.300" }}
            size="lg"
            resize="vertical"
            spellCheck={question.type === "Coding" ? false : true}
            fontFamily={question.type === "Coding" ? "monospace" : "Poppins"}
          />
        )}

        {(question.type === "Multiple-Choice" ||
          question.type === "True/False") &&
          question?.choices && (
            <RadioGroup
              value={formData[index].studentAnswer}
              onChange={(value) => updateFormData(value, index)}
            >
              <Stack direction="column">
                {Object?.entries(question?.choices)?.map(([key, value]) => (
                  <Radio
                    value={value}
                    colorScheme="secondary"
                    color={"gray.600"}
                  >
                    <Box display="flex" alignItems="center">
                      <Text mr={2}>{key}.</Text>
                      <Text>{value}</Text>
                    </Box>
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}
      </ListItem>
    );
  };

  return (
    <Box>
      <Button
        _hover={{ bg: "primary.500" }}
        bg={"highlight.primary"}
        color={"gray.50"}
        border={"none"}
        borderRadius={"100px"}
        onClick={() => setIsOpen(true)}
      >
        Start Assessment
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnOverlayClick={false}
      >
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent
          alignSelf={"center"}
          p={1}
          minW={"70vw"}
          minH={"80vh"}
          h={"fit-content"}
          bg={"background"}
          borderRadius={"6px"}
        >
          <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
            <Icon
              as={MdAssessment}
              bg={"highlight.primary"}
              color={"gray.50"}
              boxSize={"28px"}
              borderRadius={"6px"}
              p={"3px"}
            />
            <Heading
              as={"h1"}
              lineHeight={"1.7"}
              color={colors.gray[600]}
              fontSize={{ lg: "20px", xl: "22px" }}
            >
              {assessment.data.assessment_header}
            </Heading>
          </ModalHeader>

          <ModalBody>
            <Box overflowY={"scroll"} height={"70vh"} p={"3px"} pl={4}>
              <Text fontWeight={"bold"} color={"gray.600"}>
                Instructions:
              </Text>
              <Text marginBottom={3}>
                {assessment.data.assessment_instructions}
              </Text>
              <hr />
              <OrderedList spacing={3} marginTop={3}>
                {assessment?.data?.questions?.map((question: Question, index) =>
                  renderQuestion(question, index)
                )}
              </OrderedList>
              <Text textAlign={"center"} marginY={5} color={"gray.500"}>
                Please ensure that all responses are completed to the best of
                your ability before submitting this assessment.
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button
              bg={"gray.100"}
              color={"gray.500"}
              _hover={{ bg: "gray.200" }}
              borderRadius={"100px"}
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              minW={"140px"}
              bg={"highlight.primary"}
              color={"gray.50"}
              border={"none"}
              _hover={{ opacity: 0.9 }}
              loadingText="Submitting..."
              borderRadius={"100px"}
              onClick={() => submitAssessment()}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
