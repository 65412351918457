import { PaperProps, Reference } from "models/papers/PaperProps";
import { MaterialProps } from "models/posts/PostProps";
import { stylesToString } from "utils/helpers";

export interface PapersNetworkProps {
  reference_material: MaterialProps | null
  reference_paper: PaperProps;
  similar_papers: PaperProps[];
}

export interface NodePosition {
  x: number;
  y: number;
  isOrigin: boolean;
}

interface NodeOptionsProps {
  bg: string;
  category: number;
}

export interface NodeProps {
  data: PaperProps;
  category: number;
  id: string | undefined;
  isOrigin: boolean;
  highlighted: boolean;
  name: string | undefined;
  x: number;
  y: number;
  value: number | undefined;
  year: number | undefined;
  type: string | undefined;
  symbolSize: number;
  label: {
    bg: string;
    zIndex: number;
    show: boolean;
  };
  itemStyle: {
    color: string;
    shadowBlur: number;
    borderType: string;
    borderWidth: number;
    borderColor: string;
  };
}

export interface LinkProps {
  source: string | undefined;
  target: string | undefined;
}

export const handleNodeOptions = (
  node: PaperProps,
  referencePapers: PaperProps[]
): NodeOptionsProps => {
  if (referencePapers.find((p: PaperProps) => p.id === node.id)) {
    return {
      bg: "#00c1b4",
      category: 0,
    };
  }

  if (
    referencePapers.find((p: PaperProps) =>
      p.references?.find((lens: Reference) => lens.lens_id === node.lens_id)
    )
  ) {
    return {
      bg: "#FFA62B",
      category: 2,
    };
  }

  return {
    bg: "#63B3ED",
    category: 1,
  };
};

export const checkOrigin = (
  id: string | undefined,
  referencePapers: PaperProps[]
) => {
  return referencePapers.some((p: PaperProps) => p.id === id);
};

export const generateNodes = (
  data: PaperProps[],
  referencePapers: PaperProps[],
  nodesPositions: NodePosition[],
  setNodesPositions: React.Dispatch<React.SetStateAction<NodePosition[]>>,
  nodesCount: number = 10
): NodeProps[] => {
  return data.map((item: PaperProps) => {
    const isOrigin = checkOrigin(item.id, referencePapers);
    let xBoundary = 700;
    let yBoundary = 510;

    let xPos = Math.floor(Math.random() * xBoundary + 1);
    let yPos = Math.floor(Math.random() * yBoundary);

    let tries = 0;
    const maxTries = nodesCount;

    const hasOverlap = (x: number, y: number): boolean =>
      nodesPositions.some(
        (pos) => Math.abs(pos.x - x) < 60 || Math.abs(pos.y - y) < 60
      );

    while (hasOverlap(xPos, yPos) && tries < maxTries) {
      xPos = Math.floor(Math.random() * xBoundary + 1);
      yPos = Math.floor(Math.random() * yBoundary);
      tries++;
    }
    xPos += 50;
    yPos += 50;

    setNodesPositions((prev) => [...prev, { x: xPos, y: yPos, isOrigin }]);

    return {
      data: item,
      category: handleNodeOptions(item, referencePapers).category,
      id: item.id,
      isOrigin,
      highlighted: false,
      name: item?.title,
      x: xPos,
      y: yPos,
      value: item.year_published,
      year: item.year_published,
      type: item.paper_type,
      symbolSize: isOrigin ? 35 : 20,
      label: {
        bg: "none",
        zIndex: -50,
        show: isOrigin ? true : false,
      },
      itemStyle: {
        color: handleNodeOptions(item, referencePapers).bg,
        shadowBlur: 0,
        borderType: "dashed",
        borderWidth: 2,
        borderColor: "transparent",
      },
    };
  });
};

export const generateLinks = (data: PapersNetworkProps[]) => {
  return data?.reduce((acc: LinkProps[], paper) => {
    const sourceId = paper.reference_paper.id;
    const connectedPapers = paper.similar_papers || [];
    connectedPapers.forEach((connectedPaper) =>
      acc.push({ source: sourceId, target: connectedPaper.id })
    );
    return acc;
  }, []);
};

export function toolTipFormatter(params: any) {
  const { name, year, isOrigin } = params.data;
  const { color } = params;

  // format tooltip only for node element
  if (params?.dataType !== "node") return "";

  const origin = {
    width: "fit-content",
    "margin-bottom": "10px",
    "font-weight": "bold",
    "font-family": "Arial",
    color: "#00C1B4",
    "font-size": "12px",
    "background-color": "rgba(0, 193, 180, .1)",
    "font-transform": "uppercase",
    "line-height": "1.3",
    "border-radius": "3px",
    padding: "2px 6px",
  };

  const originStyle = stylesToString(origin);

  const title = {
    display: "block",
    "font-weight": "600",
    "font-size": "13px",
    "line-height": "1.3",
    "white-space": "normal",
    "text-align": "left",
    width: "95%",
    color: color ?? "#52525b",
    cursor: "pointer",
  };
  const titleStyle = stylesToString(title);

  const item = { "font-size": "11px", color: "#71717a" };
  const itemStyle = stylesToString(item);

  const label = { "font-size": "11px", "font-weight": "bold" };
  const labelStyle = stylesToString(label);

  const wrapper = {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "text-align": "left",
    padding: "0px",
    "min-width": "240px",
    "max-width": "300px",
  };
  const wrapperStyle = stylesToString(wrapper);

  const container = {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    "max-height": "300px",
    padding: "4px",
    "overflow-y": "auto",
    gap: "4px",
    "border-bottom": "none",
  };
  const containerStyle = stylesToString(container);

  const instruction = {
    color: "#a0aec0",
    width: "100%",
    "justify-content": "flex-end",
    gap: "8px",
    "align-items": "center",
    "font-size": "11px",
    "margin-top": "8px",
    "font-style": "italic",
    "text-decoration": "underline",
  };
  const instructionStyle = stylesToString(instruction);

  // origin
  const originContent = isOrigin
    ? `<Box style="${originStyle}">Source</Box>`
    : "";

  // title
  const titleContent = `<Flex style="${titleStyle}">${name}</Flex>`;

  // year
  const yearHeading = `<Flex style="${labelStyle}">Published:</Flex>`;
  const yearContent = `<Flex style="${itemStyle}">${yearHeading} ${year}</Flex>`;

  // instruction
  const instructionContent = `<Flex style="${instructionStyle}">Right click for more details</Flex>`;

  const tooltipContent = [
    originContent,
    `<Flex style="${containerStyle}">${titleContent}${yearContent}</Flex>`,
    instructionContent,
  ].join("");

  return `<Flex style="${wrapperStyle}">${tooltipContent}</Flex>`;
}
