import { useContext, useEffect, useState } from "react";
import { Accordion, Flex } from "@chakra-ui/react";

import ReadMoreButton from "../references/ReadMoreButton";
import NoDataAvailable from "../references/NoDataAvailable";
import AccordionMaterialItem from "./AccordionMaterialItem";

import { IconType } from "react-icons";

import { BotMessageContext } from "../response/BotMessage";
import { MaterialProps } from "models/posts/PostProps";

interface MaterialsProps {
  materials: MaterialProps[];
}

export interface BaseButtonProps {
  id?: string;
  icon?: IconType;
  label: string;
  onClick: () => void;
}

// limit nbr of materials to show to 3 initially
const MAX_SLICE = 3;

function MaterialsTabPanel({ materials }: MaterialsProps) {
  // States
  const [showAll, setShowAll] = useState(false);
  const [showAllMaterials, setShowAllMaterials] = useState(false);

  let materialsToShow = showAll ? materials : materials?.slice(0, MAX_SLICE);

  return (
    <>
      {materialsToShow && materialsToShow?.length > 0 ? (
        <Flex direction={"column"}>
          <Accordion defaultIndex={[0]} allowMultiple>
            {materialsToShow?.map(
              (material: MaterialProps, index: number) => (
                <AccordionMaterialItem
                  key={"term" + index}
                  index={index}
                  data={material}
                />
              )
            )}
          </Accordion>

          {/* read more/less */}
          {materials.length > MAX_SLICE && (
            <ReadMoreButton
              label={
                showAllMaterials
                  ? "view less"
                  : `view more +${materials?.length - MAX_SLICE}`
              }
              onClick={() => setShowAllMaterials(!showAllMaterials)}
            />
          )}
        </Flex>
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
}

export default MaterialsTabPanel;