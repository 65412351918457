import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentPostsData,
  setTeamData,
} from "redux/features/posts/postsSlice";

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  useTheme,
} from "@chakra-ui/react";

import { useTeamsAPI } from "api/useTeamsAPI";

import MembersTable from "./MembersTable";
import Loading from "components/ui/Loading";

import { hexToRgba } from "utils/helpers";
import { SavedFolderProps } from "models/bookmarks/SavedProps";
import { TeamProps } from "models/posts/PostProps";

interface FolderAccessListProps {
  folder: SavedFolderProps | undefined;
  isOpen: boolean;
  onClose: () => void;
}

function FolderAccessList({ folder, isOpen, onClose }: FolderAccessListProps) {
  // Hooks
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const { folderId } = useParams();
  const { getTeam } = useTeamsAPI();
  const { teamData } = useSelector(selectCurrentPostsData);
  const drawerRef: MutableRefObject<HTMLElement | null> = useRef(null);

  // State
  const [isLoading, setIsLoading] = useState(false);

  const getTeamData = async () => {
    await getTeam()
      .then((response) => {
        dispatch(setTeamData(response));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isLoading && !teamData) {
      setIsLoading(true);
      getTeamData();
    } else if (!isLoading && !!teamData) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, teamData]);

  return (
    <>
      {folder ? (
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={drawerRef}
        >
          <DrawerOverlay
            backdropFilter={"blur(3.5px)"}
            bg={"rgba(0,0,0,0.1)"}
          />

          <DrawerContent
            bg={"background"}
            h={"80vh"}
            w={"90vw"}
            borderRadius={"12px 0 0 12px"}
            p={0}
            alignSelf={"center"}
            borderColor={"gray.100"}
            borderWidth={1}
            boxShadow={"rgba(0, 0, 0, 0.2) 0px 3px 8px"}
          >
            <DrawerCloseButton size={"sm"} color={"gray.400"} />

            <DrawerHeader
              p={3}
              borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
              borderBottomWidth={1}
            >
              <Text fontSize={"14px"} color={"gray.600"}>
                Folder Access List
              </Text>
            </DrawerHeader>

            <DrawerBody
              p={3}
              display={"flex"}
              flexDir={"column"}
              gap={4}
              width={"100%"}
            >
              {!!isLoading ? (
                <Flex
                  h={"100%"}
                  w={"100%"}
                  align={"center"}
                  justify={"center"}
                  color={"gray.500"}
                  direction={"column"}
                >
                  <Loading message="Loading ..." />
                </Flex>
              ) : (
                <>
                  {!!teamData ? (
                    teamData.map((team: TeamProps, index: number) => (
                      <MembersTable
                        key={`${team.name}-${index}`}
                        teamData={team}
                        folderId={folderId}
                      />
                    ))
                  ) : (
                    <Flex
                      h={"100%"}
                      w={"100%"}
                      align={"center"}
                      justify={"center"}
                      color={"gray.500"}
                      direction={"column"}
                    >
                      <Text color={"gray.500"} textAlign={"center"}>
                        You are not part of any team to share this folder.
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      ) : (
        <></>
      )}
    </>
  );
}

export default FolderAccessList;
