import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { useMaterialsAPI } from './useMaterialsAPI';
import { errorHandler } from 'utils/helpers';
import { MaterialsResponse } from 'models/posts/PostProps';
import { useSelector } from 'react-redux';
import { selectCurrentAuthData } from 'redux/features/auth/authSlice';

export const useMaterialPrivacyMutation = () => {
    const { toggleMaterialPrivacy } = useMaterialsAPI();
    const toast = useToast();
    const queryClient = useQueryClient();
    const { user } = useSelector(selectCurrentAuthData);

    return useMutation({
        mutationFn: async ({ teamId, materialId, currentPage, isTeamContext }: { teamId: string | null | undefined, materialId: string, currentPage: number, isTeamContext: boolean }) => {
            await toggleMaterialPrivacy({ materialId });
            return { materialId, currentPage, isTeamContext, teamId }
        },
        onSuccess: async ({ materialId, currentPage, isTeamContext, teamId }) => {
            queryClient.setQueryData([isTeamContext ? 'team-materials' : 'private-materials', isTeamContext ? teamId : user?.id, currentPage], (oldData: MaterialsResponse) => ({
                ...oldData,
                materials: oldData.materials.map(material => {
                    if (material.id === materialId) {
                        material.private_to_uploader = !material.private_to_uploader
                    }
                    return material
                })
            }));
        },
        onError: (error: any, variables: { teamId: string | null | undefined; materialId: string; currentPage: number, isTeamContext: boolean }) => {
            const { teamId, isTeamContext, currentPage } = variables;
            queryClient.invalidateQueries({ queryKey: [isTeamContext ? 'team-materials' : 'private-materials', isTeamContext ? teamId : user?.id, currentPage] });
            toast({
                description: errorHandler(error).message,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            });
        },
    });
};