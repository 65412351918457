import { useEffect, useState } from "react";
import { Box, Flex, IconButton, useToast } from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import { errorHandler } from "utils/helpers";
import { useTeamsAPI } from "api/useTeamsAPI";
import { TeamMemberActivityProps } from "models/posts/PostProps";
import ActivityTable from "components/team/members/ActivityTable";
import { useRefreshContext } from "hooks/team/RefreshContext";
import { RepeatIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";

function ActivityPanel() {
  // Hooks
  const { teamId } = useParams();
  const { isRefreshing, refreshTableData } = useRefreshContext();
  const toast = useToast();
  // APIs
  const { fetchTeamMembersActivity } = useTeamsAPI();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [teamActivityData, setTeamActivityData] = useState<
    TeamMemberActivityProps[]
  >([]);

  const _cachedTeamActivities = localStorage.getItem("teamActivities");
  const cachedTeamActivities = _cachedTeamActivities
    ? JSON.parse(_cachedTeamActivities)
    : null;

  const loadTeamActivityData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeamMembersActivity(teamId);
      setTeamActivityData(response);
      localStorage.setItem("teamActivities", JSON.stringify(response));
    } catch (error) {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (teamId && !cachedTeamActivities) {
      loadTeamActivityData();
    }

    if (cachedTeamActivities) setTeamActivityData(cachedTeamActivities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  if (isLoading) {
    return (
      <Flex
        h={"100%"}
        w={"100%"}
        align={"center"}
        justify={"center"}
        color={"gray.500"}
        direction={"column"}
      >
        <Loading message="Loading team activity data ..." />
      </Flex>
    );
  }

  return (
    <Box h={"100%"} w="100%">
      <Flex h={"100%"} direction={"column"}>
        <Box>
          <IconButton
            float={"right"}
            border={"none"}
            borderRadius={"100px"}
            aria-label="Refresh Team Data"
            color={"gray.500"}
            onClick={() => {
              localStorage.removeItem("teamActivities");
              refreshTableData();
            }}
            icon={<RepeatIcon />}
            isLoading={isRefreshing}
          />
        </Box>
        <Box py="24px">
          <ActivityTable teamActivities={teamActivityData} />
        </Box>
      </Flex>
    </Box>
  );
}

export default ActivityPanel;
