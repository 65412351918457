import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";

import {
  Box,
  Button,
  Flex,
  IconButton,
  Spacer,
  Text,
  useTheme,
} from "@chakra-ui/react";

import TableData from "components/team/members/table/TableData";
import NewTeamModal from "components/team/members/NewTeamModal";

import { FaUsers } from "react-icons/fa";
import { BsPersonPlus } from "react-icons/bs";
import { RepeatIcon } from "@chakra-ui/icons";
import { useRefreshContext } from "hooks/team/RefreshContext";
import { useParams } from "react-router-dom";
import { TeamProps } from "models/posts/PostProps";

export default function MembersPanel() {
  const { isRefreshing, refreshTableData } = useRefreshContext();

  // Hooks
  const { teamId } = useParams();
  const { user } = useSelector(selectCurrentAuthData);
  let { teamData } = useSelector(selectCurrentPostsData);

  // States
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);
  const [isInvite, setIsInvite] = useState(true);

  const selectedTeam =
    teamData?.find((enterprise: TeamProps) => enterprise.id === teamId) ||
    undefined;

  const isOwner = user?.id === selectedTeam?.owner;

  // Theme
  const { colors } = useTheme();

  const createTeam = (is_invite: boolean) => {
    if (!is_invite) {
      setIsInvite(false);
    }
    setShowNewTeamModal(true);
  };

  return (
    <>
      {teamData ? (
        <Flex direction={"column"} gap={5}>
          <Flex align={"center"}>
            <Text color={colors.highlight.primary}>Members</Text>
            <Spacer />
            <Text fontSize={14} color={colors.gray["500"]} fontWeight={"bold"}>
              {selectedTeam?.name || ""}
            </Text>

            <Spacer />

            {/* refresh icon */}
            <IconButton
              border={"none"}
              borderRadius={"100px"}
              aria-label="Refresh Table"
              color={"gray.500"}
              onClick={refreshTableData}
              icon={<RepeatIcon />}
              isLoading={isRefreshing}
            />

            {/* Add Member Button */}
            {isOwner && (
              <Button
                marginLeft={2}
                bg={"highlight.primary"}
                color={"gray.50"}
                border={"none"}
                _hover={{ opacity: 0.9 }}
                borderRadius={"100px"}
                onClick={() => createTeam(true)}
              >
                <Flex display={"flex"} justify={"center"} alignItems={"center"}>
                  <BsPersonPlus style={{ marginRight: 5 }} />
                  Add Member
                </Flex>
              </Button>
            )}
          </Flex>

          <TableData
            members={selectedTeam?.members || []}
            isOwner={isOwner}
            teamData={selectedTeam}
          />
        </Flex>
      ) : (
        <Flex
          direction={"column"}
          align={"center"}
          justify={"center"}
          gap={10}
          w={"full"}
          h={"full"}
        >
          <Flex
            direction={"column"}
            align={"center"}
            justify={"center"}
            gap={2}
            mt={6}
          >
            <FaUsers color="#6c757d" fontSize="48px" />
          </Flex>

          <Flex
            direction={"column"}
            align={"center"}
            justify={"center"}
            gap={3}
          >
            {user?.user_type === "teams" ? (
              <>
                <Text color={"gray.500"} fontSize={"14px"}>
                  You do not have a team yet, start by creating a team:
                </Text>
                <Flex align={"center"} justify={"center"} gap={2}>
                  <Button
                    bg={"highlight.primary"}
                    color={"gray.50"}
                    border={"none"}
                    _hover={{ opacity: 0.9 }}
                    borderRadius={"100px"}
                    onClick={() => createTeam(false)}
                  >
                    <Flex
                      display={"flex"}
                      justify={"center"}
                      alignItems={"center"}
                    >
                      Create a Team
                    </Flex>
                  </Button>
                </Flex>
              </>
            ) : (
              <Text color={"red.500"} fontSize={"16px"}>
                You are not a member of any team, please contact the
                administration.
              </Text>
            )}
          </Flex>
        </Flex>
      )}

      <NewTeamModal
        isInvite={isInvite}
        isOpen={showNewTeamModal}
        teamId={teamId}
        onClose={() => setShowNewTeamModal(false)}
      />
    </>
  );
}
