import { Button, Flex, Icon, Td, Text, Tr, useTheme } from "@chakra-ui/react";

import { RowProps } from "./TableData";
import { hexToRgba } from "utils/helpers";
import { RiUserLine, RiUserSharedLine, RiUserStarLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

export default function Row({
  member,
  permission,
  index,
  teamData,
  isOwner,
  isRevoked,
  onManageMember,
}: RowProps) {
  const { user } = useSelector(selectCurrentAuthData);

  // Theme
  const { colors } = useTheme();

  return (
    <Tr
      key={"table-row" + index}
      transition={"all 0.3s ease"}
      _hover={{ bg: "gray.100" }}
    >
      {/* status */}
      <Td
        key={"status" + member.first_login + index}
        borderColor={"gray.100"}
        py={3}
        px={1.5}
        isTruncated
      >
        <Flex
          gap={1}
          align={"center"}
          justify={"center"}
          w={"100%"}
          bg={
            isRevoked
              ? hexToRgba(colors.red[500], 0.12)
              : hexToRgba(colors.highlight.primary, 0.12)
          }
          color={isRevoked ? "red.500" : "highlight.primary"}
          px={2}
          py={1}
          borderRadius={"12px"}
        >
          <Text fontSize={"12px"} fontWeight={500}>
            {isRevoked ? "Inactive" : "Active"}
          </Text>
        </Flex>
      </Td>

      {/* given name */}
      <Td
        key={"given_name" + member.given_name + index}
        borderColor={"gray.100"}
        py={3}
        px={1.5}
        isTruncated
        fontSize={"14px"}
      >
        {member.given_name}
      </Td>

      {/* family name */}
      <Td
        key={"family_name" + member.family_name + index}
        borderColor={"gray.100"}
        py={3}
        px={1.5}
        isTruncated
        fontSize={"14px"}
      >
        {member.family_name}
      </Td>

      {/* email */}
      <Td
        key={"email" + member.email + index}
        borderColor={"gray.100"}
        py={3}
        px={1.5}
        isTruncated
        fontSize={"14px"}
      >
        {member.email}
      </Td>

      {/* role */}
      <Td
        key={"role" + member.first_login + index}
        borderColor={"gray.100"}
        py={3}
        px={1.5}
        isTruncated
      >
        <Flex gap={1} align={"center"} color={"gray.600"} w={"fit-content"}>
          <Icon
            as={
              permission === "super admin"
                ? RiUserStarLine
                : permission === "admin"
                ? RiUserSharedLine
                : RiUserLine
            }
            boxSize={"14px"}
            color={
              permission === "super admin"
                ? "orange.400"
                : permission === "admin"
                ? "secondary.400"
                : "gray.600"
            }
          />
          <Text
            fontSize={"14px"}
            fontWeight={"500"}
            textTransform={"capitalize"}
          >
            {permission}
          </Text>
        </Flex>
      </Td>

      {/* action button */}
      <Td
        key={"date" + member.first_login + index * 2}
        borderColor={"gray.100"}
        p={0}
        isTruncated
      >
        <Button
          iconSpacing={1}
          w={"fit-content"}
          h={"fit-content"}
          minH={"fit-content"}
          fontSize={"14px"}
          fontWeight={"500"}
          borderRadius={0}
          px={3}
          bg={"transparent"}
          color={"secondary.500"}
          _hover={{ opacity: 0.8 }}
          _focus={{ bg: "transparent" }}
          isDisabled={permission === "super admin" || user?.id === member?.id}
          onClick={() => onManageMember(member?.id, permission)}
        >
          Manage
        </Button>
      </Td>
    </Tr>
  );
}
