import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";

import { errorHandler } from "utils/helpers";
import Loading from "components/ui/Loading";
import TextField from "components/posts/TextField";
import MessageBubble from "components/posts/MessageBubble";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { TeamMessageProps } from "models/posts/PostProps";
import usePostWS, { TeamSocketContext } from "hooks/posts/usePostWS";
import {
  selectCurrentPostsData,
  setUnreadMessages,
} from "redux/features/posts/postsSlice";
import { useRefreshContext } from "hooks/team/RefreshContext";
import { RepeatIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";

function PostsPanel() {
  const { isRefreshing, refreshTableData } = useRefreshContext();

  // Hooks
  const { teamId } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const { user } = useSelector(selectCurrentAuthData);
  let { unReadMessages } = useSelector(selectCurrentPostsData);
  const { teamMessages, setTeamMessages } = useContext(TeamSocketContext);

  const { teamSocket, teamSocketConnected } = usePostWS();

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [paginationData, setPaginationData] = useState({
    numPages: 0,
    nextPage: 1,
    status: false,
  });

  // Refs
  const messagesRef = useRef<HTMLDivElement>(null);

  // Handlers
  const scrollToBottomWithBehavior = (smoothScroll = true) => {
    if (paginationData.status === true) {
      return;
    }
    // DO NOT SCROLL DOWN IF HELp TOUR IS OPEN
    if (messagesRef.current) {
      const { scrollHeight } = messagesRef.current;

      smoothScroll
        ? messagesRef.current.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          })
        : (messagesRef.current.scrollTop = scrollHeight);
    }
  };

  const handleSendMessage = async (text: string) => {
    if (text.trim()) {
      const newMessage = {
        id: new Date().toISOString(),
        team_id: teamId,
        user_id: user?.id,
        given_name: user?.given_name,
        family_name: user?.family_name,
        message: text,
        read_by: [],
        created_at: new Date().toISOString(),
        date_modified: new Date().toISOString(),
        type: "message",
      };

      setTeamMessages((prevMessages: TeamMessageProps[]) => [
        ...prevMessages,
        newMessage,
      ]);

      if (teamSocketConnected && !!teamSocket) {
        teamSocket.current?.send(JSON.stringify(newMessage));
      } else {
        toast({
          description:
            "Seems like there is an issue with connecting to the server!",
          status: "error",
        });
      }
      setPaginationData({ numPages: 0, nextPage: 1, status: false });
    }
  };

  const getTeamChats = async (isFirstTime: boolean) => {
    if (!teamId) {
      return;
    }

    setIsLoading(true);
    // TODO: move this API request to useTeamAPI
    await axiosPrivate
      .get(
        `/api/team_messages?teamId=${teamId}&pageNumber=${paginationData.nextPage}`
      )
      .then((response: any) => {
        const data = response.data;
        if (isFirstTime) {
          setTeamMessages(data.messages);
          // Set Un read message badge
          if (unReadMessages > 0) {
            teamSocket.current?.send(
              JSON.stringify({
                type: "read_all",
                user_id: user?.id,
              })
            );
            dispatch(setUnreadMessages(0));
          }
          setPaginationData({
            numPages: data.num_pages,
            nextPage: data.next_page,
            status: false,
          });
        } else {
          let newList = teamMessages.concat(data.messages);
          newList.sort(function (a, b) {
            return (
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
            );
          });
          setTeamMessages(newList);
          setPaginationData({
            numPages: data.num_pages,
            nextPage: data.next_page,
            status: true,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast({
          description: errorHandler(error).message,
          status: "error",
          position: "top-right",
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (teamId) {
      getTeamChats(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  // instant scroll to bottom on load
  useEffect(() => {
    scrollToBottomWithBehavior(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, teamMessages]);

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  const conversationHeight = useBreakpointValue({
    lg: "calc(100vh - 190px)", // 148 = (8x2) + 16 + 8 + 60 + 10: py + boxSize + gap + textfield
    xl: "calc(100vh - 200px)", // 156 = (12x2) + 16 + 8 + 60 + 10
    "2xl": "calc(100vh - 206px)", // 164 = (16x2) + 16 + 8 + 60 + 10
  });

  const listStyle = {
    height: conversationHeight,
    width: "100%",
    padding: "0 6px 0 0",
  };

  if (isLoading) {
    return (
      <Flex
        h={"100%"}
        w={"100%"}
        align={"center"}
        justify={"center"}
        color={"gray.500"}
        direction={"column"}
      >
        <Loading message="Loading team chats ..." />
      </Flex>
    );
  }

  return (
    <Box w={"100%"}>
      <CustomScrollBar
        scrollableNodeProps={{ ref: messagesRef }}
        style={listStyle}
        maxW={"100%"}
        mx={"auto"}
        zIndex={1}
      >
        {paginationData.nextPage <= paginationData.numPages && (
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Button
              justifySelf={"center"}
              alignSelf={"center"}
              size="xs"
              colorScheme="secondary"
              onClick={() => getTeamChats(false)}
            >
              Load More
            </Button>
          </Box>
        )}

        <IconButton
          float={"right"}
          border={"none"}
          borderRadius={"100px"}
          aria-label="Refresh Team Data"
          color={"gray.500"}
          onClick={() => refreshTableData()}
          icon={<RepeatIcon />}
          isLoading={isRefreshing}
        />

        {teamMessages?.length > 0 ? (
          <Flex
            direction={"column"}
            justify={"flex-end"}
            align={"flex-end"}
            h={"calc(100% - 90px)"}
            w={"900px"}
            maxW={"90%"}
            mx={"auto"}
            py={"24px"}
            position={"relative"}
            _focusVisible={{ border: "none", outline: "none" }}
          >
            {/* messages content */}
            <Flex
              direction={"column"}
              pr={4}
              w={"100%"}
              _focusVisible={{ border: "none", outline: "none" }}
            >
              {teamMessages?.map((message: TeamMessageProps, index: number) => (
                <MessageBubble
                  key={message.id}
                  content={message}
                  isLastMessage={index === teamMessages?.length - 1}
                  messagesRef={messagesRef}
                />
              ))}
            </Flex>
          </Flex>
        ) : (
          <Text textAlign={"center"} color={"gray.500"} marginTop={50}>
            Be the first to start the conversation!
          </Text>
        )}
      </CustomScrollBar>

      <TextField onSendQuestion={handleSendMessage} />
    </Box>
  );
}

export default PostsPanel;
