import React, { useState } from 'react';
import { Flex, Button, Heading, Icon } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IoDocuments } from 'react-icons/io5';
import { selectCurrentAuthData } from 'redux/features/auth/authSlice';
import { selectCurrentPostsData } from 'redux/features/posts/postsSlice';
import { TeamProps } from 'models/posts/PostProps';
import MaterialsTable from './MaterialsTable';
import UploadModal from './UploadModel';

interface MaterialsProps {
    isTeamContext?: boolean;
    teamId?: string;
}

const Materials: React.FC<MaterialsProps> = ({ isTeamContext = false, teamId }) => {
    const { user } = useSelector(selectCurrentAuthData);
    const { teamData } = useSelector(selectCurrentPostsData);
    const [showMaterialUploadModal, setShowMaterialUploadModal] = useState(false);

    const selectedTeam = isTeamContext ? teamData?.find((team: TeamProps) => team.id === teamId) : undefined;
    const isOwner = isTeamContext && user?.id === selectedTeam?.owner;
    const canUpload = !isTeamContext || isOwner || selectedTeam?.members.some(m => m.user.id === user?.id && m.permission === 'collaborator');

    return (
        <Flex height="100%" direction="column" gap={3}>
            {/* Fixed Top Section */}
            <Flex
                justifyContent="space-between"
                alignItems="center"
                p={3}
                bg="white"
                borderBottom="1px solid"
                borderColor="gray.200"
                position="sticky"
                top={0}
                zIndex={1}
            >
                <Heading size={['sm', 'sm', 'sm', 'md', 'md', 'md']} color="gray.600">
                    {isTeamContext ? 'Team Materials' : 'Materials'}
                </Heading>
                {canUpload && (
                    <Button
                        maxW="100%"
                        size="md"
                        bg="highlight.primary"
                        color="neutral.100"
                        letterSpacing=".03rem"
                        fontSize="14px"
                        borderRadius="8px"
                        px={4}
                        py={3}
                        _hover={{ opacity: 0.9 }}
                        _active={{ opacity: 0.9 }}
                        onClick={() => setShowMaterialUploadModal(true)}
                    >
                        <Icon as={IoDocuments} mr={2} boxSize={5} />
                        Upload Materials
                    </Button>
                )}
            </Flex>

            {/* Scrollable Table Section */}
            <Flex flex="1" direction="column" overflow="hidden">
                <MaterialsTable isTeamContext={isTeamContext} teamId={teamId} />
            </Flex>

            {/* Modal */}
            <UploadModal
                isOpen={showMaterialUploadModal}
                setIsOpen={setShowMaterialUploadModal}
                isTeamContext={isTeamContext}
                teamId={teamId}
            />
        </Flex>
    );
};

export default Materials;