import { useEffect, useMemo, useState } from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  useTheme,
} from "@chakra-ui/react";


import { hexToRgba } from "utils/helpers";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { MaterialProps } from "models/posts/PostProps";
import { IoDocumentLock } from "react-icons/io5";

interface AccordionMaterialProps {
  data: MaterialProps;
  index: number;
}

function AccordionMaterialItem({ data, index }: AccordionMaterialProps) {
  // States
  const [borderColor, setBorderColor] = useState<string | undefined>(
    "gray.200"
  ); // NOTE: recognize clicked citation source by border color change
  const [showFullSummary, setShowFullSummary] = useState(false);


  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  const bg = isLight ? "background" : hexToRgba(colors.secondary[100], 0.2);
  const linksColor = isLight ? "secondary.400" : "gray.600";
  const linksBgColor = isLight ? "secondary.100" : "gray.200";
  const boxShadow = isLight
    ? "md"
    : "rgb(25, 62, 78, 0.6) 0px 6px 24px 0px, rgb(25, 62, 78, 0.6) 0px 0px 0px 1px";

  const fontSize = useBreakpointValue({ lg: "14px", "2xl": "15px" });

  // const { isGuideOpen, currentMode } = useSelector(selectCurrentGuideData);
  // const applyIsGuideOpenStyles =
  //   isGuideOpen && currentMode === "PAGE_INSTRUCTIONS";

  useEffect(() => {
    setBorderColor("primary.300");
    // if (openAccordionIndex === index) {
    //   setBorderColor("primary.300");

    //   const timer = setTimeout(() => {
    //     setBorderColor("gray.200");
    //   }, 2000);

    //   return () => clearTimeout(timer);
    // } else {
    //   setBorderColor("gray.200");
    // }
  }, [index]);

  const processedAbstract = useMemo(() => {
    if (!data.material_json.summary) {
      return "";
    } else {
      if (showFullSummary) {
        return data.material_json.summary;
      } else return data.material_json.summary?.substring(0, 350) + "...";
    }
  }, [data, showFullSummary]);

  return (
    <AccordionItem
      position={"relative"}
      borderRadius={"md"}
      transition={"all ease .3s"}
      borderWidth={1}
      // borderColor={applyIsGuideOpenStyles ? "transparent" : borderColor}
      // bg={applyIsGuideOpenStyles ? "transparent" : bg}
      borderColor={borderColor}
      bg={bg}
      _hover={{ boxShadow: boxShadow }}
      // ref={openAccordionIndex === index ? openAccordionRef : null}
      mb={3}
      p={4}
    >
      {/* {applyIsGuideOpenStyles && (
        <Box
          borderRadius={"md"}
          bg={"rgba(0, 0, 0, 0.3)"}
          position={"absolute"}
          top={0}
          left={0}
          right={0}
          bottom={0}
          pointerEvents={"none"}
          zIndex={1300}
          border={"none"}
        />
      )} */}

      <Flex align={"center"} gap={2} justify={"space-between"}>
        <Flex gap={4}>
          {/* Left part */}
          <Flex direction={"column"} justifyContent={"space-between"} pb={5}>
            <Box>
              <Icon as={IoDocumentLock} color={"gray.500"} boxSize={4} />
            </Box>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              fontSize={"10px"}
              h={4}
              w={4}
              background={hexToRgba(colors.highlight.primary, 0.15)}
              color={"highlight.primary"}
              borderRadius={4}
              fontWeight={"700"}
            >
              {index + 1}
            </Flex>
          </Flex>

          {/* Right part */}
          <Box textAlign={"start"}>
            {/* Title */}
            <Text
              color={"gray.700"}
              fontWeight={"400"}
              fontSize={fontSize}
              lineHeight={"1.3"}
              mb={2}
            >
              {/* {data.material_json.metadata.document_tite} */}
              {data.filename}
            </Text>

            {/* Creation date year & Title */}
            <Box
              fontStyle={"italic"}
              my={1}
              color={"gray.400"}
              fontSize={"sm"}
              fontWeight={"400"}
            >
              {data.material_json.metadata.date_created || "Year not available"}
            </Box>
          </Box>
        </Flex>

        <AccordionButton
          p={0}
          w={"fit-content"}
          h={"fit-content"}
          _hover={{ backgroundColor: "transparent" }}
        >
          <AccordionIcon
            as={MdKeyboardDoubleArrowDown}
            color={"gray.500"}
            boxSize={"22px"}
            p={1}
            bg={"gray.100"}
            borderRadius={"50%"}
            transition={"ease-in-out .3s"}
          />
        </AccordionButton>
      </Flex>

      <AccordionPanel py={2} px={0}>
        <Flex direction={"column"} justify={"space-between"} h={"100%"}>
          <Flex direction={"column"}>
            {/* Abstraction */}
            {data.material_json.summary?.length && (
              <Box my={4}>
                <>
                  <Badge
                    fontSize={"10px"}
                    mb={3}
                    px={2}
                    py={0.5}
                    color={"gray.500"}
                    bg={"gray.200"}
                    borderRadius={4}
                  >
                    summary
                  </Badge>
                  <Flex>
                    <Text fontSize={"14px"} color={"gray.600"}>
                      {processedAbstract}
                      <Text
                        cursor={"pointer"}
                        as={"span"}
                        fontSize={"13px"}
                        color={"blue.400"}
                        fontWeight={"500"}
                        onClick={() => setShowFullSummary((s) => !s)}
                      >
                        {showFullSummary ? " less" : " more"}
                      </Text>
                    </Text>
                  </Flex>
                </>
              </Box>
            )}
          </Flex>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default AccordionMaterialItem;
