import React, { MouseEventHandler, useState } from "react";
import { Box, Text, Flex, Avatar, AvatarGroup, Button } from "@chakra-ui/react";
import { format } from "date-fns";
import { FaCrown } from "react-icons/fa";
import { useSelector } from "react-redux";

import { InviteProps, TeamProps } from "models/posts/PostProps";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import NewTeamModal from "components/team/members/NewTeamModal";

// Props for ProjectListItem Component
interface TeamsListItemProps {
  team: TeamProps;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const TeamsListItem: React.FC<TeamsListItemProps> = ({ team, onClick }) => {
  const { user } = useSelector(selectCurrentAuthData);

  // States
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);
  const [isInvite, setIsInvite] = useState(true);

  const isOwner = user?.id === team?.owner ? true : false;
  const pendingInvites =
    team?.invites?.filter((invite: InviteProps) => invite.accepted === false) ||
    [];

  // Handlers
  const inviteMember = (is_invite: boolean) => {
    if (!is_invite) {
      setIsInvite(false);
    }
    setShowNewTeamModal(true);
  };
  return (
    <Flex
      key={team.id}
      align="center"
      justify="space-between"
      p={3}
      _hover={{ bg: "gray.50" }}
      onClick={onClick}
      cursor={"pointer"}
    >
      {/* Project Name + Field + Description */}
      <Box width="20%" pr={"10px"}>
        <Flex align="center" mb={1}>
          <Text fontWeight="bold" isTruncated>
            {team.name}
          </Text>
        </Flex>
      </Box>
      {/* Created At */}
      <Box width="10%">
        <Text fontSize="sm">
          {format(new Date(team.created_at), "MMMM d, yyyy")}
        </Text>
      </Box>
      {/* Team Members (Owner gets a crown) */}
      <Flex width="20%" justify="center">
        <AvatarGroup size="sm" max={2}>
          {team?.members?.map((member: any) => (
            <Box key={member.user.id} position="relative">
              <Avatar
                name={member.user.given_name}
                src={member.avatarUrl || ""}
                size="sm"
                border="2px solid white"
              />
              {member.user.id === team.owner && (
                <Box
                  position="absolute"
                  top="-6px"
                  right="9.5px"
                  color="yellow.400"
                  fontSize="12px"
                >
                  <FaCrown />
                </Box>
              )}
            </Box>
          ))}
        </AvatarGroup>
      </Flex>
      {/* Pending Invites */}
      <Box width="5%">
        {pendingInvites?.length >= 1 ? (
          <Text fontSize="sm" color={"rgba(23, 74, 228, 0.6)"}>
            {pendingInvites?.length}
          </Text>
        ) : (
          <Text fontSize="sm" color={"gray.400"}>
            -:-
          </Text>
        )}
      </Box>
      {/* Invite New Members */}
      <Box width="20%" textAlign={"right"}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            inviteMember(true);
          }}
          fontSize={"12px"}
          fontWeight={"500"}
          color={"rgba(23, 74, 228, 0.6)"}
          bg="white"
          borderRadius={"20px"}
          borderColor={"rgba(23, 74, 228, 0.3)"}
          borderWidth={1}
          borderStyle={"dashed"}
          py={1.5}
          px={3}
          transition={"all .2s ease"}
          _hover={{
            textDecoration: "none",
            bg: "rgba(23, 74, 228, 0.1)",
            transition: "all .2s ease",
          }}
          textAlign={"center"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          isDisabled={!isOwner}
        >
          + Invite Member
        </Button>
      </Box>

      <NewTeamModal
        isInvite={isInvite}
        teamId={team?.id}
        isOpen={showNewTeamModal}
        onClose={() => setShowNewTeamModal(false)}
      />
    </Flex>
  );
};

export default TeamsListItem;
