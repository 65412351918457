import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";

import {
  Avatar,
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  useTheme,
} from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import Loading from "components/ui/Loading";
import RenameFolderModal from "./modal/RenameFolderModal";
import { SavedFolderProps } from "models/bookmarks/SavedProps";

import { EditIcon } from "@chakra-ui/icons";
import { FaRegTrashAlt } from "react-icons/fa";
import { BsFolderCheck } from "react-icons/bs";
import { TeamProps } from "models/posts/PostProps";
import { useParams } from "react-router-dom";

interface FoldersItemProps {
  folder: SavedFolderProps;
  deleting?: boolean;
  onDeleteFolder: (folderId: string) => void;
  onUpdateFolderTitle: (title: string, id: string) => void;
  onClick: () => void;
  updatingFolder: boolean;
  renameModal: {
    isOpen: boolean;
    folder?: SavedFolderProps;
  };
  onOpenRenameModal: (folder: SavedFolderProps) => void;
  onCloseRenameModal: () => void;
}

export default function FoldersItem({
  folder,
  deleting,
  onDeleteFolder,
  onUpdateFolderTitle,
  onClick,
  updatingFolder,
  renameModal,
  onOpenRenameModal,
  onCloseRenameModal,
}: FoldersItemProps) {
  // Hooks
  const { teamId } = useParams();
  const { user } = useSelector(selectCurrentAuthData);
  let { teamData } = useSelector(selectCurrentPostsData);
  const selectedTeam =
    teamData?.find((enterprise: TeamProps) => enterprise.id === teamId) ||
    undefined;

  // States
  const [show, setShow] = useState<boolean>(false);

  // Theme
  const { colors } = useTheme();

  const {
    name,
    literatures: literature,
    compounds,
    assays,
    botMessages,
  } = folder;

  const countElements =
    (assays?.length ?? 0) +
    (compounds?.length ?? 0) +
    (botMessages?.length ?? 0) +
    (literature?.length ?? 0);

  return (
    <Flex
      position={"relative"}
      align={"center"}
      color={"gray.600"}
      _hover={{
        color: "blue.500",
        bg: hexToRgba(colors.blue[300], 0.15),
        cursor: "pointer",
      }}
      p={4}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      borderRadius={"8px"}
      bg={"background"}
      gap={2}
      border={`1px solid ${hexToRgba(colors.secondary[100], 0.5)}`}
      onClick={onClick}
      pointerEvents={deleting ? "none" : "auto"}
    >
      <Icon
        bg={hexToRgba(colors.blue[300], 0.15)}
        as={BsFolderCheck}
        color={"secondary.400"}
        borderRadius={"8px"}
        p={2}
        boxSize={[null, null, null, "40px", "42px", "44px"]}
      />

      {/* folder name */}
      <Flex
        direction={"column"}
        gap={1}
        w={"100%"}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
      >
        <Text
          fontSize={"14px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          w={"95%"}
          lineHeight={"1.3"}
        >
          {name}
        </Text>
        <Text fontSize={"12px"} color={"neutral.800"} lineHeight={1.2}>
          {countElements} saved elements
        </Text>
      </Flex>

      {/* Avatar and delete folder item */}
      <Flex
        position={"absolute"}
        right={3}
        top={"50%"}
        transform={"translateY(-50%)"}
      >
        <Tooltip label={folder.user_full_name} aria-label="Folder Owner">
          <Avatar size="2xs" name={folder.user_full_name} />
        </Tooltip>

        {show &&
          (deleting ? (
            <Box ml={1}>
              <Loading small />
            </Box>
          ) : selectedTeam?.owner === user?.id || folder?.user === user?.id ? (
            <Flex>
              <Icon
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteFolder(folder.id);
                }}
                cursor={"pointer"}
                as={FaRegTrashAlt}
                boxSize={"14px"}
                color={"gray.500"}
                ml={3}
                mr={3}
                _hover={{ color: "red.500" }}
              />
              <EditIcon
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenRenameModal(folder);
                }}
              />
            </Flex>
          ) : (
            <></>
          ))}
      </Flex>

      <RenameFolderModal
        isOpen={renameModal.isOpen}
        onUpdateFolderTitle={onUpdateFolderTitle}
        onOpenRenameModal={onOpenRenameModal}
        selectedFolderId={renameModal.folder?.id || ""}
        onCloseRenameModal={onCloseRenameModal}
        existingTitle={renameModal.folder?.name || ""}
        updatingFolder={updatingFolder}
      />
    </Flex>
  );
}
