import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { MaterialProps, MaterialsResponse } from "models/posts/PostProps";


export const useMaterialsAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const getMaterials = async ({ teamId, page = 1, itemsPerPage = 20 }: { teamId: string | null | undefined, page: number | undefined, itemsPerPage: number | undefined }): Promise<MaterialsResponse> => {
    const response = await axiosPrivate.get(`/api/materials`, {
      params: {
        teamId,
        page,
        itemsPerPage
      }
    });
    return response.data;
  };

  // New function to fetch a single material
  const getMaterial = async ({ materialId }: { materialId: string }): Promise<MaterialProps> => {
    const response = await axiosPrivate.get(`/api/material/${materialId}`);
    return response.data;
  };

  const removeMaterial = async ({ teamId, id }: { teamId: string | null | undefined, id: string }) => {
    const response = await axiosPrivate.delete(`/api/material/${id}`, {
      params: {
        teamId
      }
    });
    return response.data;
  };

  const toggleMaterialPrivacy = async ({ materialId }: { materialId: string | undefined }) => {
    const response = await axiosPrivate.patch(`/api/material/${materialId}`);
    return response.data;
  };

  return {
    getMaterials,
    getMaterial,
    removeMaterial,
    toggleMaterialPrivacy
  };
};
