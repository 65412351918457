import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { setTeamData } from "redux/features/posts/postsSlice";

import { Box, Flex } from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import { useTeamsAPI } from "api/useTeamsAPI";
import { errorHandler } from "utils/helpers";
import MainPanelError from "components/ui/MainPanelError";
import useFetchSavedElements from "hooks/bookmarks/useFetchSavedElements";
import { useRefreshContext } from "hooks/team/RefreshContext";
import AccessDisclaimer from "components/team/access/Disclaimer";
import { TeamProps } from "models/posts/PostProps";

export default function Team() {
  // States
  const [errMessage, setErrMessage] = useState<any>();
  const { isRefreshing } = useRefreshContext();

  const { teamId } = useParams();

  // Hooks
  const dispatch = useDispatch();
  const { getTeam } = useTeamsAPI();
  const { user } = useSelector(selectCurrentAuthData);

  const hasResponse = (
    error: unknown
  ): error is { response: { data: { message: string } } } => {
    return (error as any)?.response?.data?.message !== undefined;
  };

  useFetchSavedElements();

  const { data, isLoading, error } = useQuery({
    queryKey: ["team"],
    queryFn: getTeam,
    retry: 1,
  });

  useEffect(() => {
    if (data) dispatch(setTeamData(data));
    if (error) {
      setErrMessage(
        hasResponse(error)
          ? error.response.data.message
          : "An unexpected error occurred"
      );
    }
  }, [data, dispatch, error]);

  const selectedTeam =
    data?.find((enterprise: TeamProps) => enterprise.id === teamId) ||
    undefined;

  const selectedMember = selectedTeam?.members?.find(
    (m: any) => m?.user?.id === user?.id
  );

  return (
    <>
      {/* if team is restricted from accessing the team resources */}
      {!!selectedMember?.is_revoked && <AccessDisclaimer />}

      {/* active team member views */}
      {!selectedMember?.is_revoked && (
        <Flex
          direction="column"
          height="100%"
          // width={["90%", "80%"]}
          width={["90%", "90%"]}
          maxW="98%"
          wrap="wrap"
          mx="auto"
          p={4}
          gap={"16px"}
          overflow={"hidden"}
        >
          {/* Content */}
          <Box pt={2} height={"calc(100% - 58px)"} width="100%">
            {isLoading || isRefreshing ? (
              <Flex
                h={"100%"}
                w={"100%"}
                align={"center"}
                justify={"center"}
                color={"gray.500"}
                direction={"column"}
              >
                <Loading message="Loading team data.." />
              </Flex>
            ) : error && errMessage !== "You do not have a team" ? (
              <MainPanelError errorMessage={errorHandler(error).message} />
            ) : (
              <Outlet />
            )}
          </Box>
        </Flex>
      )}
    </>
  );
}
