import {
  Flex,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import ActionButton from "components/buttons/ActionButton";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import {
  SavedFolderProps,
  SavedLiteratureProps,
} from "models/bookmarks/SavedProps";
import { useMemo } from "react";
import { BsPaperclip } from "react-icons/bs";
import { GoBookmarkSlash } from "react-icons/go";
import { LuExternalLink } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";
import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";
import { getPaperColor } from "components/ui/helpers";
import { TeamProps } from "models/posts/PostProps";

interface Props {
  content: SavedLiteratureProps[];
}

interface DeleteElementProps {
  elementType: "COMPOUND" | "LITERATURE" | "ASSAY" | "BOTMESSAGE";
  content: {
    elementId: string | undefined;
    is_private?: boolean;
  };
}

const cols = [
  { name: "Name", width: "50%" },
  { name: "Lens ID", width: "10%" },
  { name: "Year", width: "10%" },
  { name: "Type", width: "15%" },
  { name: "Actions", width: "15%" },
];

function Literature({ content }: Props) {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const { folderId, teamId } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const { user } = useSelector(selectCurrentAuthData);
  let { teamData } = useSelector(selectCurrentPostsData);
  const { savedFolders } = useSelector(selectCurrentSavedData);

  const selectedTeam =
    teamData?.find((enterprise: TeamProps) => enterprise.id === teamId) ||
    undefined;

  // Navigation
  const navigate = useNavigate();

  // Theme
  const { lGradient2 } = GetGradients();
  const { colors } = useTheme();

  const cleanItems = useMemo(
    () => content?.filter((item) => item !== undefined),
    [content]
  );

  // Handler: delete item from bookmark folder
  async function handleDeleteItem({
    elementType,
    content,
  }: DeleteElementProps) {
    if (!content) return;

    try {
      const newFolders = savedFolders.map((f: SavedFolderProps) => {
        if (f.id === folderId) {
          return {
            ...f,
            literatures: f.literatures?.filter(
              (item: { id: string; title: string }) =>
                item.id !== content.elementId
            ),
          };
        }

        return f;
      });

      dispatch(setSuccess(newFolders));

      await axiosPrivate.put(`${environment.BACKEND_API}/api/remove_elements`, {
        folderId,
        elementType,
        content,
      });
    } catch (error: any) {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 1500,
        position: "top-right",
      });
      console.log(error);
    }
  }

  return (
    <Flex direction={"column"} gap={4} w={"100%"}>
      <Flex align={"center"} gap={2}>
        <Flex
          bg={hexToRgba(colors.blue[300], 0.15)}
          borderRadius={"70% 30% 30% 70% / 60% 40% 60% 40%"}
          p={1.5}
        >
          <Icon as={BsPaperclip} boxSize={"16px"} />
        </Flex>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Literature
        </Text>
      </Flex>

      <TableContainer>
        <Table variant="simple" size="sm" position={"relative"}>
          {/* Head */}
          <Thead whiteSpace={"nowrap"}>
            <Tr overflow={"hidden"}>
              {cols.map((col, index) => {
                return (
                  <Th
                    textOverflow={"ellipsis"}
                    key={"lits-table-header-" + index}
                    isTruncated={col?.name === "Aliases"}
                    borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
                    borderBottomWidth={1}
                    color={"blue.500"}
                    fontWeight={"500"}
                    textAlign={"center"}
                    w={col?.width}
                  >
                    {col?.name}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          {/* Body */}
          <Tbody
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {cleanItems.map((lit, index) => {
              return (
                <Tr
                  key={"lit-row" + index}
                  _hover={{ bgGradient: lGradient2 }}
                  transition="all 0.25s ease"
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                >
                  {/* Name */}
                  <Td
                    borderColor={"gray.100"}
                    w={"50%"}
                    maxW={"300px"}
                    fontSize={"13px"}
                    p={1.5}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    {lit?.title}
                  </Td>

                  {/* LensID */}
                  <Td
                    borderColor={"gray.100"}
                    w={"10%"}
                    fontSize={"12px"}
                    p={1.5}
                    textAlign={"center"}
                  >
                    {lit?.lens_id}
                  </Td>

                  {/* year published */}
                  <Td
                    borderColor={"gray.100"}
                    w={"10%"}
                    fontSize={"12px"}
                    p={1.5}
                    textAlign={"center"}
                  >
                    {/* NOTE: tp prevent 0-valued year_published */}
                    {!!parseInt(lit?.year_published)
                      ? lit?.year_published
                      : "-"}
                  </Td>

                  {/* Type */}
                  <Td borderColor={"gray.100"} w={"15%"} p={1.5} py={5}>
                    <Flex
                      borderRadius={"4px"}
                      p={1}
                      w={"fit-content"}
                      textAlign={"center"}
                      bg={getPaperColor(lit?.paper_type?.toLowerCase())}
                      color={"background"}
                      fontSize={{ lg: "10px", xl: "12px" }}
                      lineHeight={1.2}
                    >
                      {lit?.paper_type ?? "Unclassified"}
                    </Flex>
                  </Td>

                  {/* Action Buttons */}
                  <Td borderColor={"gray.100"} w={"15%"} isTruncated p={1.5}>
                    <Flex justify={"center"} align={"center"}>
                      {selectedTeam?.owner === user?.id ||
                      savedFolders.find((x) => x?.id === folderId)?.user ===
                        user?.id ? (
                        <ActionButton
                          inBookmarks
                          aria-label={"Delete"}
                          label={"Delete"}
                          icon={<GoBookmarkSlash />}
                          onClick={() =>
                            handleDeleteItem({
                              elementType: "LITERATURE",
                              content: { elementId: lit?.id },
                            })
                          }
                        />
                      ) : (
                        <></>
                      )}

                      <ActionButton
                        inBookmarks
                        aria-label={"Open"}
                        label={"Open"}
                        icon={<LuExternalLink />}
                        onClick={() =>
                          navigate(
                            lit.is_private
                              ? `/data/proprietary/literature/${lit?.id}`
                              : `/data/core/MoleculeLake/data/literature/${lit?.id}`
                          )
                        }
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}

export default Literature;
