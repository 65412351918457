import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  StackDivider,
  Text,
  Tooltip,
  useBreakpointValue,
  useTheme,
  VStack,
} from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import { useTeamsAPI } from "api/useTeamsAPI";
import { TeamProps } from "models/posts/PostProps";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import TeamsListItem from "./TeamsListItem";
import { FaCircleInfo, FaUsers } from "react-icons/fa6";

import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentPostsData, setTeamData } from "redux/features/posts/postsSlice";
import NewTeamModal from "components/team/members/NewTeamModal";
import TeamsViewBreadcrumb from "./Breadcrumb";
import { hexToRgba } from "utils/helpers";

// const titles = ["members", "folders", "literature", "posts", "activity"];
const titles = ["members", "folders", "materials", "posts", "activity"];

export default function TeamsView() {
  // Hooks
  const { teamId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useSelector(selectCurrentAuthData);
  let { unReadMessages } = useSelector(selectCurrentPostsData);

  // States
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);

  //   APIs
  const dispatch = useDispatch();
  const { getTeam } = useTeamsAPI();

  const { data, isLoading } = useQuery({
    queryKey: ["team"],
    queryFn: getTeam,
    retry: 1,
  });

  useEffect(() => {
    if (data) dispatch(setTeamData(data));
  }, [data, dispatch]);

  // Styles
  const contentHeight = useBreakpointValue({
    lg: "calc(100vh - 16px)",
    xl: "calc(100vh - 24px)",
    "2xl": "calc(100vh - 32px)",
  });

  const listStyle = {
    height: contentHeight,
    width: "calc(100% - 2px)", // NOTE: don't change this value! 100% as width, weirdly, blocks the window resize
  };

  // Theme
  const { colors } = useTheme();

  const selectedTeam =
    data?.find((enterprise: TeamProps) => enterprise.id === teamId) ||
    undefined;

  const selectedMember = selectedTeam?.members?.find(
    (m: any) => m?.user?.id === user?.id
  );

  // Is admin, super admin or team onwer
  const isAdmin =
    user?.id === selectedTeam?.owner ||
      selectedMember?.permission === "admin" ||
      selectedMember?.permission === "super admin"
      ? true
      : false;

  const urlPaths = location.pathname?.split("/");
  const teamView = titles.includes(urlPaths[urlPaths?.length - 1] ?? "members")
    ? urlPaths[urlPaths?.length - 1] ?? "members"
    : "members";

  // Handlers
  const handleCardClick = (team: TeamProps) => {
    navigate(`/team/${team?.id}`);
  };

  const createTeam = () => {
    setShowNewTeamModal(true);
  };

  // if Loading
  if (isLoading)
    return (
      <Flex
        direction={"column"}
        h={"100%"}
        w={"100%"}
        align={"center"}
        justify={"center"}
      >
        <Loading message="Loading teams.." />
      </Flex>
    );

  return (
    <CustomScrollBar style={listStyle}>
      <Flex
        direction="column"
        gap={[4, 10]}
        p={4}
        width={["90%", "90%"]}
        maxW="98%"
        wrap="wrap"
        mx="auto"
      >
        {/* Tabs */}
        <Flex
          w={"100%"}
          justify={"center"}
          align={"center"}
          py={3}
          borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
          borderBottomWidth={1}
        >
          <Flex w={"100%"} justify="space-between" align={"center"}>
            <TeamsViewBreadcrumb team={selectedTeam} currentView={teamView} />

            <Flex flexDirection={"row"}>
              {teamId && (
                <>
                  {titles.map((title, index) => {
                    // FIXME: Skip rendering certain titles based on conditions,
                    // but return null instead of using return;
                    if (!isAdmin && title === "Activity") return null;
                    // if (!data && title === "Literature") return null;

                    const isSelected = location.pathname.includes(
                      title.toLowerCase()
                    );
                    const isRoot =
                      location.pathname === `/team/${teamId}` && index === 0;
                    const isActive = isSelected || isRoot;
                    return (
                      <Box
                        key={index}
                        px={5}
                        py={2}
                        cursor="pointer"
                        w={"fit-content"}
                        textAlign={"center"}
                        borderRadius={"99px"}
                        bg={
                          isActive
                            ? hexToRgba(colors.blue[300], 0.15)
                            : "transparent"
                        }
                        color={isActive ? "blue.500" : "gray.500"}
                        transition={"all .3s ease"}
                        onClick={() =>
                          navigate(
                            `/team/${teamId}${title === "members" ? "" : "/" + title
                            }`
                          )
                        }
                      >
                        <HStack justifyContent={"space-between"}>
                          <Text fontSize="md" textTransform={"capitalize"}>
                            {title}
                          </Text>
                          {title === "Posts" && unReadMessages > 0 && (
                            <Box
                              bg={"red.500"}
                              borderRadius={"full"}
                              width={2.5}
                              height={2.5}
                            ></Box>
                          )}
                        </HStack>
                      </Box>
                    );
                  })}
                </>
              )}

              {user?.user_type === "teams" && data?.length > 0 && !teamId && (
                <Flex align="start">
                  <Flex
                    direction={"column"}
                    flex={1}
                    gap={4}
                    color={"blue.500"}
                  ></Flex>
                  {/* new team button */}
                  <Button
                    onClick={() => createTeam()}
                    fontSize={"12px"}
                    fontWeight={"500"}
                    color={"rgba(23, 74, 228, 0.6)"}
                    bg="white"
                    borderRadius={"20px"}
                    borderColor={"rgba(23, 74, 228, 0.3)"}
                    borderWidth={1}
                    borderStyle={"solid"}
                    py={1.5}
                    px={3}
                    transition={"all .2s ease"}
                    _hover={{
                      textDecoration: "none",
                      bg: "rgba(23, 74, 228, 0.1)",
                      transition: "all .2s ease",
                    }}
                    w={"120px"}
                    textAlign={"center"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    Create Team
                  </Button>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>

        {!teamId && (
          <>
            {/* No team created */}
            {(!data || data?.length === 0) && (
              <Flex
                direction={"column"}
                align={"center"}
                justify={"center"}
                gap={10}
                w={"full"}
                h={"full"}
              >
                <Flex
                  direction={"column"}
                  align={"center"}
                  justify={"center"}
                  gap={2}
                  mt={6}
                >
                  <FaUsers color="#6c757d" fontSize="48px" />
                </Flex>

                <Flex
                  direction={"column"}
                  align={"center"}
                  justify={"center"}
                  gap={3}
                >
                  {user?.user_type === "teams" ? (
                    <>
                      <Text color={"gray.500"} fontSize={"14px"}>
                        You do not have a team yet, start by creating a team:
                      </Text>
                      <Flex align={"center"} justify={"center"} gap={2}>
                        <Button
                          bg={"highlight.primary"}
                          color={"gray.50"}
                          border={"none"}
                          _hover={{ opacity: 0.9 }}
                          borderRadius={"100px"}
                          onClick={() => createTeam()}
                        >
                          <Flex
                            display={"flex"}
                            justify={"center"}
                            alignItems={"center"}
                          >
                            Create a Team
                          </Flex>
                        </Button>
                      </Flex>
                    </>
                  ) : (
                    <Text color={"red.500"} fontSize={"16px"}>
                      You are not a member of any team, please contact the
                      administration.
                    </Text>
                  )}
                </Flex>
              </Flex>
            )}

            {/* All Teams (list) */}
            {data && data.length > 0 && (
              <Box>
                <Flex gap={2} align={"center"} mb={6}>
                  <Text fontSize={"md"}>All Teams</Text>
                </Flex>

                {/* Header Row */}
                <Flex
                  justify="space-between"
                  fontWeight="bold"
                  fontSize="14px"
                  px={4}
                  py={2}
                  borderBottom="2px solid #e2e8f0"
                  color="gray.600"
                  bg="gray.50"
                  align={"center"}
                >
                  <Box width="20%" pr={"10px"}>
                    <Flex justify={"space-between"} align={"center"}>
                      Team Name
                    </Flex>
                  </Box>
                  <Box width="10%">Created At</Box>
                  <Box width="20%" textAlign="center">
                    Team Members
                  </Box>
                  <Box width="5%" textAlign="right">
                    <Flex justify={"space-between"} align={"center"}>
                      Invites
                      <Tooltip label="Pending invites" hasArrow>
                        <IconButton
                          size="md"
                          fontSize="lg"
                          variant="ghost"
                          color={"rgba(23, 74, 228, 0.6)"}
                          marginLeft="2"
                          icon={<FaCircleInfo />}
                          aria-label={`Pending invites info`}
                        />
                      </Tooltip>
                    </Flex>
                  </Box>
                  <Box width="20%" textAlign={"right"}>
                    Actions
                  </Box>
                </Flex>
                {/* Body Rows */}
                {data?.map((team: TeamProps) => (
                  <VStack
                    key={"allp-" + team.id}
                    align="stretch"
                    spacing={0}
                    divider={<StackDivider borderColor="gray.200" />}
                  >
                    <TeamsListItem
                      team={team}
                      onClick={() => handleCardClick(team)}
                    />
                  </VStack>
                ))}
              </Box>
            )}

            <NewTeamModal
              isInvite={false}
              teamId={teamId}
              isOpen={showNewTeamModal}
              onClose={() => setShowNewTeamModal(false)}
            />
          </>
        )}
      </Flex>

      <Outlet />
    </CustomScrollBar>
  );
}
