import { memo } from "react";
import { Box } from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import MaterialsTabPanel from "./MaterialsTabPanel";
import MainPanelError from "components/ui/MainPanelError";
import { MaterialProps } from "models/posts/PostProps";

interface SourcesComProps {
  materials: MaterialProps[];
  allFetched: boolean;
  allFetchFailed: boolean;
}

function Materials({ materials, allFetched, allFetchFailed }: SourcesComProps) {
  // loading
  if (!allFetched)
    return (
      <Box my={6}>
        <Loading message={"Loading materials..."} />
      </Box>
    );

  if (allFetchFailed) {
    return (
      <Box my={3}>
        <MainPanelError errorMessage={"Error loading materials"} />
      </Box>
    );
  }

  return <MaterialsTabPanel materials={materials} />;
}

export default memo(Materials);
