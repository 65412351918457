import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useQueries } from "@tanstack/react-query";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import { AxiosError } from "axios";

import {
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import Sources from "../sources";
import Proteins from "../proteins";
import Molecules from "../molecules";
import { BotMessageContext } from "../response/BotMessage";

import { useCompoundsAPI } from "api/useCompoundsAPI";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { useLiteraturesAPI } from "api/useLiteraturesAPI";

import { ProteinProps, SourceProps } from "models/chat/MessageProps";
import { CompoundProps } from "models/compounds/CompoundProps";
import { PapersNetworkProps } from "../sources/helpers";
import { useProteinsAPI } from "api/useProteinsAPI";
import { MaterialProps } from "models/posts/PostProps";
import { useMaterialsAPI } from "api/materials/useMaterialsAPI";
import Materials from "../materials";

interface ReferencesProps {
  sources: SourceProps[];
  molecules: string[] | undefined;
  proteins: string[] | undefined;
}

function References({ sources, molecules, proteins }: ReferencesProps) {
  // Context
  const { openAccordionIndex, resetOpenAccordionIndex } =
    useContext(BotMessageContext);

  // Hooks
  const { isGuideOpen, currentMode } = useSelector(selectCurrentGuideData);
  const { isHighlighted } = useGuideTour();
  const { fetchSimilarPapers } = useLiteraturesAPI();
  const { getMaterial } = useMaterialsAPI();
  const { fetchCompoundsByName } = useCompoundsAPI();
  const { fetchProteinById } = useProteinsAPI();

  let sourcesTabHighlighted = isHighlighted("chat_sources");
  let materialsTabHighlighted = isHighlighted("chat_materials");
  let moleculesTabHighlighted = isHighlighted("chat_molecules");
  const applyIsGuideOpenStyles =
    isGuideOpen && currentMode === "PAGE_INSTRUCTIONS";

  const materials = sources.filter(source => source.db_type === 'material');
  sources = sources.filter(source => source.db_type !== 'material');

  // API
  // * Fetch sources
  const sourcesQueries = useQueries({
    queries: sources.map((source: SourceProps) => ({
      queryKey: ["literatures", "network", source.id, source.db_type],
      queryFn: fetchSimilarPapers,
      staleTime: Infinity, // always fresh since it rarely changes
      gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
    })),
  });

  // * Fetch materials
  const materialsQueries = useQueries({
    queries: materials.map((material: SourceProps) => ({
      queryKey: ["chat-materials", material.id],
      queryFn: () =>
        getMaterial({
          materialId: material.id
        }),
      staleTime: Infinity, // always fresh since it rarely changes
      gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
    })),
  });

  //   * Fetch referenced compounds
  const moleculesQueries = useQueries({
    queries: (molecules || []).map((cmpd: string) => {
      return {
        queryKey: ["compounds-by-name", cmpd],
        queryFn: fetchCompoundsByName,
        staleTime: Infinity, // always fresh since it rarely changes
        gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
      };
    }),
  });

  // Fetch proteins
  const proteinsQueries = useQueries({
    queries: (proteins || []).map((prot: string) => {
      return {
        queryKey: ["protein-by-name", prot],
        queryFn: fetchProteinById,
        staleTime: Infinity, // always fresh since it rarely changes
        gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
      };
    }),
  });

  const allSourcesFinished = sourcesQueries.every((query) => !query.isLoading);
  const allSourcesErrorsFound = sourcesQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });

  const allMaterialsFinished = materialsQueries.every((query) => !query.isLoading);
  const allMaterialsErrorsFound = materialsQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });

  const allMolsFinished = moleculesQueries.every((query) => !query.isLoading);
  const allMolsErrorsFound = moleculesQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });

  const allProtsFinished = proteinsQueries.every((query) => !query.isLoading);
  const allProtsErrorsFound = proteinsQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });

  const sourcesData = useMemo(() => {
    return sourcesQueries
      .filter((query) => query.data !== undefined)
      .map((query) => query.data as PapersNetworkProps);
  }, [sourcesQueries]);

  const materialsData = useMemo(() => {
    return materialsQueries
      .filter((query) => query.data !== undefined)
      .map((query) => query.data as MaterialProps);
  }, [materialsQueries]);

  const moleculesData = useMemo(() => {
    return moleculesQueries
      .filter((query) => !!query.data)
      .map((query) => query.data as CompoundProps);
  }, [moleculesQueries]);

  const proteinsData = useMemo(() => {
    return proteinsQueries
      .filter((query) => !!query.data)
      .map((query) => query.data as ProteinProps);
  }, [proteinsQueries]);

  const hasSources = sources && !!sources?.length && !!sourcesData?.length;
  const hasMaterials = materials && !!materials?.length && !!materialsData?.length;
  const hasProteins = proteins && !!proteins?.length && !!proteinsData?.length;
  const hasMolecules =
    molecules && !!molecules?.length && !!moleculesData?.length;

  // If bot reply has no sources and no molecules
  if (!hasSources && !hasMaterials && !hasMolecules && !hasProteins) {
    return <></>;
  }

  return (
    <Tabs
      position="relative"
      variant="unstyled"
      index={!!openAccordionIndex ? 0 : undefined}
    >
      <TabList
        bg={"transparent"}
        color={"gray.500"}
        borderBottomWidth={1}
        borderColor={applyIsGuideOpenStyles ? "transparent" : "gray.200"}
      >
        {hasSources && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            bg={sourcesTabHighlighted ? "background" : "inherit"}
            _selected={{
              color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary",
            }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={"5px"}
          >
            <Box id="chat_sources" px={3}>
              SOURCES
            </Box>
          </Tab>
        )}
        {hasMaterials && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            bg={materialsTabHighlighted ? "background" : "inherit"}
            _selected={{
              color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary",
            }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={"5px"}
          >
            <Box id="chat_materials" px={3}>
              MATERIALS
            </Box>
          </Tab>
        )}
        {hasMolecules && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            bg={moleculesTabHighlighted ? "background" : "inherit"}
            _selected={{
              color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary",
            }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={"5px"}
          >
            <Box id="chat_molecules" px={3}>
              MOLECULES
            </Box>
          </Tab>
        )}
        {hasProteins && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            // bg={proteinsTabHighlighted ? "background" : "inherit"} // TODO:
            _selected={{
              color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary",
            }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={"5px"}
          >
            <Box
              // id="chat_molecules" // TODO:
              px={3}
            >
              PROTEINS
            </Box>
          </Tab>
        )}
      </TabList>
      <TabIndicator
        mt={"-2px"}
        h="2px"
        bg={applyIsGuideOpenStyles ? "inherit" : "highlight.primary"}
        borderRadius="1px"
      />
      <TabPanels>
        {hasSources && (
          <TabPanel p={1}>
            <Sources
              sources={sourcesData}
              allFetched={allSourcesFinished}
              allFetchFailed={allSourcesErrorsFound}
            />
          </TabPanel>
        )}
        {hasMaterials && (
          <TabPanel p={1}>
            <Materials
              materials={materialsData}
              allFetched={allMaterialsFinished}
              allFetchFailed={allMaterialsErrorsFound}
            />
          </TabPanel>
        )}
        {hasMolecules && (
          <TabPanel p={1}>
            <Molecules
              molecules={moleculesData}
              allFetched={allMolsFinished}
              allFetchFailed={allMolsErrorsFound}
            />
          </TabPanel>
        )}
        {hasProteins && (
          <TabPanel p={1}>
            <Proteins
              proteins={proteinsData}
              allFetched={allProtsFinished}
              allFetchFailed={allProtsErrorsFound}
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}

export default References;
