import React from 'react';
import { Button, IconButton, HStack } from '@chakra-ui/react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';

const Pagination: React.FC<{
    currentPage: number;
    totalPages: number;
    handlePageChange: (page: number) => void;
}> = ({ currentPage, totalPages, handlePageChange }) => {
    const getPageNumbers = () => {
        const delta = 2;
        const range = [];
        for (
            let i = Math.max(2, currentPage - delta);
            i <= Math.min(totalPages - 1, currentPage + delta);
            i++
        ) {
            range.push(i);
        }

        if (currentPage - delta > 2) {
            range.unshift('...');
        }
        if (currentPage + delta < totalPages - 1) {
            range.push('...');
        }

        range.unshift(1);
        if (totalPages > 1) {
            range.push(totalPages);
        }

        return range;
    };

    return (
        <HStack spacing="1" alignSelf={'flex-end'}>
            <IconButton
                aria-label="Previous page"
                icon={<MdOutlineKeyboardArrowLeft />}
                onClick={() => handlePageChange(currentPage - 1)}
                isDisabled={currentPage === 1}
                size="sm"
            />

            {getPageNumbers().map((pageNumber, index) => (
                <Button
                    key={index}
                    onClick={() =>
                        typeof pageNumber === 'number'
                            ? handlePageChange(pageNumber)
                            : null
                    }
                    variant={currentPage === pageNumber ? 'solid' : 'outline'}
                    colorScheme={currentPage === pageNumber ? 'blue' : 'gray'}
                    isDisabled={pageNumber === '...'}
                    size="sm"
                    minW="8"
                >
                    {pageNumber}
                </Button>
            ))}

            <IconButton
                aria-label="Next page"
                icon={<MdOutlineKeyboardArrowRight />}
                onClick={() => handlePageChange(currentPage + 1)}
                isDisabled={currentPage === totalPages}
                size="sm"
            />
        </HStack>
    );
};
export default Pagination