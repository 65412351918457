import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMaterialsAPI } from "./useMaterialsAPI";
import { useToast } from "@chakra-ui/react";
import { errorHandler } from "utils/helpers";
import { MaterialsResponse } from "models/posts/PostProps";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

export const useDeleteMaterialMutation = () => {
    const { removeMaterial } = useMaterialsAPI();
    const queryClient = useQueryClient();
    const toast = useToast();
    const { user } = useSelector(selectCurrentAuthData);

    return useMutation({
        mutationFn: async ({ teamId, id, currentPage, isTeamContext }: { teamId: string | null | undefined, id: string, currentPage: number, isTeamContext: boolean }) => {
            await removeMaterial({ teamId, id });
            return { id, currentPage, teamId, isTeamContext }
        },
        onSuccess: async ({ id, currentPage, teamId, isTeamContext }) => {
            queryClient.setQueryData([isTeamContext ? 'team-materials' : 'private-materials', isTeamContext ? teamId : user?.id, currentPage], (oldData: MaterialsResponse) => ({
                ...oldData,
                materials: oldData.materials.filter(material => material.id !== id)
            }));
        },
        onError: (error: any, variables: { teamId: string | null | undefined; id: string; currentPage: number, isTeamContext: boolean }) => {
            const { teamId, isTeamContext, currentPage } = variables;
            queryClient.invalidateQueries({ queryKey: [isTeamContext ? 'team-materials' : 'private-materials', isTeamContext ? teamId : user?.id, currentPage] });
            toast({
                description: errorHandler(error).message,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            });
        },
    });
};
