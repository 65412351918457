import { useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";

import BreadcrumbHome from "components/ui/BreadcrumbHome";
import { TeamProps } from "models/posts/PostProps";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { truncateString } from "components/ui/helpers";

interface TeamsViewBreadcrumbProps {
  team: TeamProps | undefined;
  currentView: string;
}

function TeamsViewBreadcrumb({ team, currentView }: TeamsViewBreadcrumbProps) {
  // Navigation
  const navigate = useNavigate();

  // Hooks
  const { teamId } = useParams();

  return (
    <Breadcrumb
      spacing={1}
      separator={<ChevronRightIcon color="gray.500" />}
      fontSize={"14px"}
      mr={"auto"}
      w={"400px"}
    >
      {/* Index */}
      <BreadcrumbItem>
        <BreadcrumbLink
          onClick={() => navigate("/team")}
          lineHeight={1}
          p={1.5}
        >
          <BreadcrumbHome />
        </BreadcrumbLink>
      </BreadcrumbItem>

      {/* Team Name */}
      {team && (
        <BreadcrumbItem
          isCurrentPage
          color={"gray.500"}
          fontWeight={"500"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          <BreadcrumbLink
            w={"100%"}
            cursor={"pointer"}
            _hover={{
              textDecoration: "underline",
            }}
            onClick={() => navigate(`/team/${teamId}`)}
          >
            <Text
              fontSize={"small"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {truncateString(team?.name, 20)}
            </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {/* Current Team View */}
      {teamId && (
        <BreadcrumbItem
          isCurrentPage
          color={"gray.500"}
          fontWeight={"500"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          <BreadcrumbLink w={"100%"}>
            <Text
              fontSize={"small"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              textTransform={"capitalize"}
            >
              {currentView}
            </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}

export default TeamsViewBreadcrumb;
