import { useLocation, useParams } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import {
  ProprietarySourceProps,
  SourceProps,
  getCoreSource,
  getProprietarySource,
  getMRNASource,
} from "../helpers";
import AgnosticDataDisplay from "components/library/core/AgnosticDataDisplay";
import PrivateMaterialsView from "./PrivateMaterialsView";

const SourceTable = () => {
  const { source, layerName, teamId } = useParams();

  const coreSource = getCoreSource(source);
  const mRNASource = getMRNASource(source);
  const proprietarySources = getProprietarySource(source);

  const location = useLocation();
  const pathName = location.pathname;

  if (
    ![
      "MOLECULES",
      "LITERATURE",
      "MATERIAL",
      "ASSAYS",
      "PROPRIETARY",
      "PROTEINS",
      "3'UTRS",
      "5'UTRS",
    ].includes(source?.toUpperCase() || "")
  ) {
    // return <Navigate to={"/not-found"} replace />;
  }

  return (
    <>
      {!coreSource && !proprietarySources && !mRNASource ? (
        <Box>Source not found</Box>
      ) : (
        layerName === "Private" ?
          <PrivateMaterialsView />
          :
          <AgnosticDataDisplay
            source={
              // layerName === "Private" || teamId
              //   ? // (teamId && pathName === `/team/${teamId}/literature`)
              //   (proprietarySources as ProprietarySourceProps)
              //     :
              layerName === "mRNALake"
                ? (mRNASource as SourceProps)
                : (coreSource as SourceProps)
            }
          />
      )}
    </>
  );
};

export default SourceTable;
