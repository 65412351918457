import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { DeleteIcon, LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Text, TableContainer, Tag, useToast } from '@chakra-ui/react';
import { selectCurrentAuthData } from 'redux/features/auth/authSlice';
import { selectCurrentPostsData } from 'redux/features/posts/postsSlice';
import { MaterialProps, TeamProps } from 'models/posts/PostProps';
import { errorHandler } from 'utils/helpers';
import { FileTypeIcon, formatFileSize } from './helper';
import { CustomScrollBar } from 'components/ui/CustomScrollBar';
import Loading from 'components/ui/Loading';
import MainPanelError from 'components/ui/MainPanelError';
import NoResultsFound from 'components/ui/NoResultsFound';
import Pagination from './Pagination';
import DeleteModal from 'components/ui/DeleteModal';
import { useMaterialsAPI } from 'api/materials/useMaterialsAPI';
import { useDeleteMaterialMutation } from 'api/materials/useDeleteMaterialMutation';
import { useMaterialPrivacyMutation } from 'api/materials/useMaterialPrivacyMutation';

interface MaterialsTableProps {
    isTeamContext?: boolean;
    teamId?: string;
}

const tableStyle = {
    height: '100%',
    width: '100%',
    padding: '0',
};

const MaterialsTable: React.FC<MaterialsTableProps> = ({ isTeamContext = false, teamId }) => {
    const toast = useToast();
    const { user } = useSelector(selectCurrentAuthData);
    const { teamData } = useSelector(selectCurrentPostsData);
    const { getMaterials } = useMaterialsAPI();
    const deleteMaterialMutation = useDeleteMaterialMutation();
    const materialPrivacyMutation = useMaterialPrivacyMutation();

    const [currentPage, setCurrentPage] = useState(1);
    const [showDeletePaperModal, setShowDeletePaperModal] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState<null | MaterialProps>(null);

    const selectedTeam = isTeamContext ? teamData?.find((team: TeamProps) => team.id === teamId) : undefined;
    const isOwner = isTeamContext && user?.id === selectedTeam?.owner;

    const { data, isLoading, error } = useQuery({
        queryKey: [isTeamContext ? 'team-materials' : 'private-materials', isTeamContext ? teamId : user?.id, currentPage],
        queryFn: () => getMaterials({ teamId: isTeamContext ? selectedTeam?.id : null, page: currentPage, itemsPerPage: 20 }),
        enabled: !isTeamContext || !!selectedTeam,
    });

    const tableHeads = ['File', 'Size', 'Status', 'Uploaded by', 'Uploaded at', 'Actions'];
    const totalPages = data?.total_pages || 1;

    const handlePageChange = (page: number) => setCurrentPage(page);
    const handleDelete = (material: MaterialProps) => {
        setSelectedMaterial(material);
        setShowDeletePaperModal(true);
    };

    const handleConfirmDeleteMaterial = async () => {
        if (!selectedMaterial || (selectedMaterial.uploaded_by.id !== user?.id && !(isTeamContext && isOwner))) return;
        try {
            const res = await deleteMaterialMutation.mutateAsync({ teamId: isTeamContext ? selectedTeam?.id : null, id: selectedMaterial.id, currentPage, isTeamContext });
            toast({
                description: res ? 'Material deletion is initiated..' : 'Deletion failed.',
                status: 'info',
                position: 'top-right',
            });
        } catch (error) {
            toast({ description: errorHandler(error).message, status: 'error', position: 'top-right' });
        } finally {
            setShowDeletePaperModal(false);
            setSelectedMaterial(null);
        }
    };

    const handleTogglePrivacy = async (material: MaterialProps) => {
        if (!isTeamContext) return;
        try {
            await materialPrivacyMutation.mutateAsync({ teamId: isTeamContext ? selectedTeam?.id : null, materialId: material.id, currentPage, isTeamContext });
        } catch (error) {
            toast({ description: 'Failed to change material privacy', status: 'error', position: 'top-right' });
        }
    };

    return (
        <Flex direction="column" height="100%" overflow="hidden">
            {/* Scrollable Table */}
            <TableContainer flex="1" overflowY="auto">
                <CustomScrollBar style={tableStyle}>
                    <Table variant="simple" bg="white">
                        <Thead position="sticky" top={0} bg="gray.100" boxShadow="none" zIndex={1}>
                            <Tr>
                                {tableHeads.map((h, idx) => (
                                    <Th
                                        key={idx}
                                        minW={
                                            h === 'File' ? '170px' :
                                                h === 'Size' ? '70px' :
                                                    h === 'Status' ? '90px' :
                                                        h === 'Uploaded by' ? '140px' :
                                                            h === 'Uploaded at' ? '40px' :
                                                                h === 'Actions' ? '40px' : 'fit-content'
                                        }
                                        boxShadow="none"
                                        p={2}
                                        textAlign="left"
                                        border="none"
                                        borderLeftRadius={idx === 0 ? 'md' : 'none'}
                                        borderRightRadius={idx === tableHeads.length - 1 ? 'md' : 'none'}
                                        borderRight={idx !== tableHeads.length - 1 ? '1px solid' : 'none'}
                                        borderRightColor="gray.200"
                                    >
                                        <Flex alignItems="center" justifyContent="space-between" color="gray.500">
                                            <Box>{h}</Box>
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {isLoading ? (
                                <Tr>
                                    <Td border="none" colSpan={tableHeads.length} py={10}>
                                        <Loading message={`Loading ${isTeamContext ? 'team' : 'private'} materials`} />
                                    </Td>
                                </Tr>
                            ) : error ? (
                                <Tr>
                                    <Td colSpan={tableHeads.length} py={10}>
                                        <Flex direction="column" alignItems="center">
                                            <MainPanelError errorMessage={errorHandler(error).message} />
                                        </Flex>
                                    </Td>
                                </Tr>
                            ) : !data?.materials.length ? (
                                <Tr>
                                    <Td colSpan={tableHeads.length} py={10}>
                                        <Flex direction="column" alignItems="center">
                                            <NoResultsFound sourceName="materials" />
                                        </Flex>
                                    </Td>
                                </Tr>
                            ) : (
                                data.materials.map((material: MaterialProps) => (
                                    <Tr
                                        key={`table-row-${material.id}`}
                                        boxShadow="none"
                                        bg="transparent"
                                        transition="background 0.3s ease"
                                        borderColor="gray.100"
                                        _hover={{ cursor: 'pointer', bg: 'gray.100' }}
                                    >
                                        <Td maxW="20px" px={1.5} isTruncated>
                                            <Flex alignItems="center">
                                                <Box mr={3}><FileTypeIcon type={material.file_mime} /></Box>
                                                <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" fontSize={['12px', '14px', '14px']} fontWeight="400">
                                                    {material.filename}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td maxW="20px" px={1.5}><Text fontSize={['12px', '14px', '14px']} fontWeight="400">{formatFileSize(material.size)}</Text></Td>
                                        <Td maxW="20px" px={1.5}>
                                            <Tag
                                                colorScheme={material.embedded ? 'green' : material.processed && !material.embedded ? 'orange' : 'red'}
                                                size="sm"
                                            >
                                                {material.embedded ? 'Embedded' : material.processed && !material.embedded ? 'Processing' : 'Not processed'}
                                            </Tag>
                                        </Td>
                                        <Td maxW="120px" px={1.5} isTruncated>
                                            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" fontSize={['12px', '14px', '14px']} fontWeight="400">
                                                {material.uploaded_by.fullname}
                                            </Text>
                                        </Td>
                                        <Td maxW="20px" px={1.5}>
                                            <Text fontSize={['12px', '14px', '14px']} fontWeight="400">{new Date(material.created_at).toLocaleDateString()}</Text>
                                        </Td>
                                        <Td maxW="140" px={1.5}>
                                            <Flex gap={1}>
                                                {(material.uploaded_by.id === user?.id || (isTeamContext && isOwner)) && (
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        bg="red.400"
                                                        color="gray.700"
                                                        borderRadius="full"
                                                        maxW="120px"
                                                        w="fit-content"
                                                        px={2}
                                                        py={1}
                                                        gap={1}
                                                        cursor="pointer"
                                                        transition="all 0.2s"
                                                        _hover={{ transform: 'scale(0.98)' }}
                                                        onClick={(e) => { e.stopPropagation(); handleDelete(material); }}
                                                    >
                                                        <DeleteIcon />
                                                    </Flex>
                                                )}
                                                {isTeamContext && material.uploaded_by.id === user?.id && (
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        bg={material.private_to_uploader ? 'yellow.400' : 'gray.200'}
                                                        color="gray.700"
                                                        borderRadius="full"
                                                        maxW="120px"
                                                        w="fit-content"
                                                        px={2}
                                                        py={1}
                                                        gap={1}
                                                        cursor="pointer"
                                                        transition="all 0.2s"
                                                        _hover={{ transform: 'scale(0.98)' }}
                                                        onClick={(e) => { e.stopPropagation(); handleTogglePrivacy(material); }}
                                                    >
                                                        {material.private_to_uploader ? <LockIcon /> : <UnlockIcon />}
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))
                            )}
                        </Tbody>
                    </Table>
                </CustomScrollBar>
            </TableContainer>

            {/* Fixed Pagination */}
            {data && totalPages > 1 && (
                <Flex shrink={0} p={3} borderTop="1px solid" borderColor="gray.200" bg="white">
                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </Flex>
            )}

            {/* Delete Modal */}
            <DeleteModal
                isOpen={showDeletePaperModal}
                onClose={() => setShowDeletePaperModal(false)}
                onConfirm={handleConfirmDeleteMaterial}
                header="Delete Material"
            >
                {deleteMaterialMutation.isPending ? (
                    <Loading message="Deleting material ..." />
                ) : (
                    <Box fontSize="14px" mt={4}>
                        <Text>Are you sure you want to delete material</Text>
                        <Text fontWeight="bold">{` ${selectedMaterial?.filename}?`}</Text>
                    </Box>
                )}
            </DeleteModal>
        </Flex>
    );
};

export default MaterialsTable;